import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import ApiUser from "../../../API/ApiUser";
import styles from "./requestResetPasswordStyles";
import RequestResetPasswordView from "./requestResetPasswordView";
import { withRouter } from "react-router-dom/";

class RequestResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: false,
      errorMessage: "",
      showSuccess: false,
      successMessage: "",
      email: "",
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.requestResetPassword = this.requestResetPassword.bind(this);
  }

  componentDidMount() {
    document.title = "Nouveau mot de passe | Landing Zone";
  }

  handleFormChange(event) {
    this.setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }

  async requestResetPassword(event) {
    event.preventDefault();

    if (!this.state.email) {
      return;
    }

    let res = await ApiUser.requestPasswordReset(this.state.email);

    if (res.status != 200) {
      this.setState((prevState) => ({
        ...prevState,
        showError: true,
        showSuccess: false,
        errorMessage: "Cet email n'existe pas dans notre base de données",
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        showError: false,
        showSuccess: true,
        successMessage:
          "Si l'adresse e-mail fournie correspond à un compte enregistré, vous recevrez un lien pour réinitialiser votre mot de passe.",
      }));
    }
  }

  render() {
    return (
      <>
        <RequestResetPasswordView
          {...this.props}
          state={this.state}
          requestResetPassword={this.requestResetPassword}
          handleFormChange={this.handleFormChange}
        />
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(RequestResetPassword)
);
