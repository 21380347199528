const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: theme.palette.darkGrey.main,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  formContainer: {
    paddingTop: "30px",
    paddingBottom: "30px",
    width: "90%",
  },
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "20ch",
    },
  },
  imageCard: {
    marginLeft: 10,
    maxWidth: 200,
    marginTop: 20,
  },
  imageCardMedia: {
    width: "100%",
    height: "100%",
    backgroundColor: "#E5E8EB",
  },
  uploadFile: {
    marginTop: 10,
    marginLeft: 10,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  enigmasButtons: {
    margin: theme.spacing(1),
  },
  link: {
    textDecoration: "none",
  },
  textFieldBig: {
    minWidth: 400,
  },
  listContainer: {
    display: "flex",
    justifyContent: "center",
  },
  list: {
    backgroundColor: theme.palette.lightGrey.main,
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    borderRadius: 5,
    listStyle: "none",
    width: "60%",
  },
  buttonListPlus: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 5,
    color: "white",
  },
  returnButtonLink: {
    textDecoration: "none",
    margin: theme.spacing(2),
  },
  whiteTextfield: {
    backgroundColor: "white",
  },
  saveButton: {
    backgroundColor: theme.palette.orange.main,
    color: "#ffffff",
    fontSize: 18,
    fontWeight: 900,
    borderWidth: "0px",
    "&:hover": {
      backgroundColor: theme.palette.orange.dark,
    },
  },
  textFieldContainer: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 8,
    padding: 18,
    width: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 12,
  },
  textFieldContainerLight: {
    backgroundColor: "transparent",
    borderRadius: 8,
    padding: 0,
    width: "80%",

    marginBottom: 12,
  },
  headerTextFieldDark: {
    color: "black",
    borderRadius: 5,
    fontWeight: 800,
  },
  switchFormControl: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    color: "white",
    borderRadius: 5,
    fontWeight: 700,
  },
  headerTextField: {
    color: "white",
    borderRadius: 5,
    fontWeight: 700,
  },
  textField: {
    width: 200,
    minWidth: 200,
    backgroundColor: "white",
    borderRadius: 8,
  },
  mapButton: {
    backgroundColor: theme.palette.orange.main,
    color: "#ffffff",
    height: 40,
    fontSize: 12,
    borderRadius: 8,
    "&:hover": {
      backgroundColor: theme.palette.orange.dark,
    },
  },
  select: {
    backgroundColor: "white",
    padding: 10,
    marginTop: 6,
    marginLeft: 6,
    borderWidth: 1,
    borderRadius: 5,
    border: "1px solid #c0c0c0",
    width: 200,
  },
  qrButton: {
    color: "#ffffff",
    borderWidth: "0px",
    backgroundColor: "#F48A21",
    "&:hover": {
      backgroundColor: theme.palette.orange.dark,
    },
    height: 30,
  },
  linkQrCodeButton: {
    textDecoration: "none",
    color: "white",
    backgroundColor: "#C3CDEC",
    fontWeight: 400,
    fontSize: 12,
    margin: theme.spacing(1),
    textTransform: "none",
    "&:hover": {
      color: theme.palette.orange.dark,
    },
  },
  banner: {
    color: "#ffffff",
    backgroundColor: theme.palette.orange.main,
    textAlign: "center",
    fontSize: 16,
    fontWeight: 900,
    padding: "15px",
    color: "white",

    // marginBottom: "10px",
  },
  buttonDisabled: {
    backgroundColor: "#8e999d",
    cursor: "default",
    "&:hover": {
      backgroundColor: "#8e999d",
    },
  },
});

export default styles;
