import axios from "axios";
import { apiHost } from "../constants/ApiConstants";
// import JwtUtils from "../utils/jwtUtils";

// Axios instance with interceptors
const apiClient = axios.create({
  withCredentials: true,
  baseURL: apiHost,
});

// apiClient.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     try {
//       const originalRequest = error.config;

//       if (
//         error.response.status === 403 &&
//         error.response.data === "jwt expired" &&
//         !originalRequest._retry
//       ) {
//         originalRequest._retry = true;
//         const tokens = await JwtUtils.getNewTokens();

//         if (tokens) {
//           originalRequest.headers[
//             "Authorization"
//           ] = `Bearer ${tokens.accessToken}`;
//           return apiClient(originalRequest);
//         }
//       }

//       return Promise.reject(error);
//     } catch (e) {
//       console.log("e ", e);
//     }
//   }
// );

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log("error.response.data ", error.response.data);
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data.redirectTo &&
      !window.location.pathname.startsWith("/statistiques")
    ) {
      window.location.href = error.response.data.redirectTo;
    }
    return Promise.reject(error);
  }
);

apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default apiClient;
