const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: theme.palette.darkGrey.main,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  formContainer: {
    paddingTop: "30px",
    paddingBottom: "30px",
    width: "90%",
  },
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "20ch",
    },
  },
  imageArRecognition: {
    borderRadius: 8,
    height: "300px",
    objectFit: "contain",
    backgroundColor: "#E5E8EB",
  },
  imageCardVintage: {
    marginLeft: 10,
    width: 411 / 4,
    height: 482 / 4,
    marginTop: 0,
  },
  imageCardVintageTab: {
    marginLeft: 10,
    width: 1024 / 4,
    height: 998 / 4,
    marginTop: 0,
  },
  imageCardClue: {
    marginLeft: 10,
    width: 410 / 4,
    height: 700 / 4,
    marginTop: 0,
  },
  imageCardClueTab: {
    marginLeft: 10,
    width: 1152 / 4,
    height: 998 / 4,
    marginTop: 0,
  },
  imageCardMedia: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    backgroundColor: "#E5E8EB",
  },
  imageInfos: {
    paddingTop: 18,
    paddingLeft: 8,
    color: "#494949ff",
    fontSize: 12,
    fontWeight: 600,
  },
  uploadFile: {
    marginTop: 10,
    marginLeft: 10,
    maxWidth: 300,
    fontWeight: 700,
    fontSize: 12,
  },
  addRowButton: {
    marginTop: 10,
    marginLeft: 10,
    maxWidth: 250,
    fontWeight: 500,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  beta: {
    marginTop: -8,
    marginLeft: 14,
    display: "inline-block",
    backgroundColor: "#ffcc00" /* Couleur de fond jaune */,
    color: "#fff" /* Couleur du texte blanc */,
    padding: "4px 8px" /* Espacement intérieur */,
    fontSize: "15px" /* Taille de la police */,
    fontWeight: "bold" /* Texte en gras */,
    borderRadius: "4px" /* Coins arrondis */,
    textTransform: "uppercase" /* Texte en majuscules */,
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
  },
  enigmasButtons: {
    margin: theme.spacing(1),
  },
  textfieldResponses: {
    minWidth: "90%",
    backgroundColor: "white",
  },
  textfieldPropositions: {
    minWidth: "95%",
    backgroundColor: "white",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "50%",
    backgroundColor: "white",
    padding: 6,
    borderRadius: 8,
    border: "1px solid #c0c0c0",
  },
  helperText: {
    fontSize: 14,
    fontWeight: 100,
    marginLeft: 12,
  },
  helperTextSmall: {
    fontSize: 14,
    fontWeight: 100,
    marginLeft: 8,
  },
  textFieldBig: {
    minWidth: "90%",
    backgroundColor: "white",
    borderRadius: 8,
  },
  returnButtonLink: {
    textDecoration: "none",
    margin: theme.spacing(2),
  },
  imageDimensionsText: {
    paddingLeft: 8,
    color: "#494949ff",
    fontSize: 12,
  },
  saveButton: {
    backgroundColor: theme.palette.orange.main,
    color: "#ffffff",
    fontSize: 18,
    fontWeight: 900,
    borderWidth: "0px",
    "&:hover": {
      backgroundColor: theme.palette.orange.dark,
    },
  },

  textFieldContainer: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 8,
    padding: 18,
    width: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 12,
  },
  textFieldContainerLight: {
    backgroundColor: "transparent",
    borderRadius: 8,
    padding: 0,
    width: "80%",
    display: "flex",
    flexDirection: "column",
    marginBottom: 12,
  },
  headerTextFieldDark: {
    color: "black",
    borderRadius: 5,
    fontWeight: 800,
  },
  headerTextField: {
    color: "white",
    borderRadius: 5,
    fontWeight: 700,
  },
  textField: {
    width: 200,
    minWidth: 200,
    backgroundColor: "white",
    borderRadius: 8,
  },
  addSpace: {
    height: "210px",
  },
  boxOptional: {
    borderRadius: 12,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 12,
    paddingBottom: 16,
    width: "100%",
    // minHeight: 600,
  },
  boxOptionalRight: {
    minHeight: 200,
    backgroundColor: "#DCE0EC",
    borderRadius: 12,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 12,
    paddingBottom: 16,
    width: "100%",
  },
  rightContainerTopOverflow: {
    paddingTop: 65,
    paddingBottom: 64,
  },
  spaceBetweenGrids: {
    height: 20,
  },
  warningBeta: {
    padding: 8,
    borderRadius: 8,
    fontWeight: 200,
    fontSize: 15,
    marginRight: 12,
    color: "#cf4a4a",
    textAlign: "center",
  },
  primaryOptionalTitle: {
    marginTop: -32,
    padding: 8,
    borderRadius: 8,
    fontWeight: 800,
    fontSize: 18,
    color: "#48506B",
    backgroundColor: "white",
    boxShadow: "1px 1px 6px rgba(0, 0, 0, 0.15)",
  },
  optionalTitle: {
    marginTop: 0,
    padding: 8,
    borderRadius: 8,
    fontWeight: 1000,
    fontSize: 15,
    color: "#262936",
    // backgroundColor: "white",
    // boxShadow: "1px 1px 6px rgba(0, 0, 0, 0.15)",
  },
  orTitle: {
    marginLeft: -10,
    marginTop: 12,
    padding: 8,
    margin: 12,
    borderRadius: "50%",
    fontWeight: 800,
    fontSize: 15,
    color: "#48506B",
    backgroundColor: "white",
    boxShadow: "1px 1px 6px rgba(0, 0, 0, 0.45)",
  },
  hrVertical: {
    borderLeft: "1px solid #C1C6D3",
    height: "650px",
    flex: 1,
    marginRight: -10,
  },
  hrVerticalOtherBox: {
    borderLeft: "1px solid #C1C6D3",
    height: "650px",
    flex: 1,
    padding: 12,
    marginRight: -10,
  },
  headerLocations: {
    fontSize: 12,
    fontWeight: 800,
    marginLeft: 12,
  },
  filename: {
    marginTop: 6,
    fontSize: 12,
    fontWeight: 400,
  },
  textfieldWhite: {
    backgroundColor: "white",
  },
  mapButton: {
    backgroundColor: theme.palette.orange.main,
    color: "#ffffff",
    fontSize: 10,
    borderRadius: 8,
    height: 40,
    marginTop: 9,
    "&:hover": {
      backgroundColor: theme.palette.orange.dark,
    },
  },
  banner: {
    color: "#ffffff",
    backgroundColor: theme.palette.orange.main,
    textAlign: "center",
    fontSize: 16,
    fontWeight: 900,
    padding: "15px",
    color: "white",

    // marginBottom: "10px",
  },
  buttonDisabled: {
    backgroundColor: "#8e999d",
    cursor: "default",
    "&:hover": {
      backgroundColor: "#8e999d",
    },
  },
});

export default styles;
