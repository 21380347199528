import Axios from "axios";

// import JwtUtils from "../utils/jwtUtils";
import { apiHost } from "../constants/ApiConstants";
import apiClient from "./ApiClient";

export default class ApiEnigma {
  static async uploadEnigma(enigmaDatas) {
    // const tokens = await JwtUtils.getValidTokens();
    // if (!tokens) {
    //   return { status: null, data: null };
    // }
    // const authHeader = "Bearer " + tokens.accessToken;

    const dataForm = new FormData();

    if (enigmaDatas.clue?.imageFile)
      dataForm.append(
        "imageCollection",
        enigmaDatas.clue.imageFile,
        "-clueimage-" + enigmaDatas.clue.imageFile.name
      );
    if (enigmaDatas.vintage?.imageFile)
      dataForm.append(
        "imageCollection",
        enigmaDatas.vintage.imageFile,
        "-vintageimage-" + enigmaDatas.vintage.imageFile.name
      );
    if (enigmaDatas.arObject?.file) {
      dataForm.append(
        "imageCollection",
        enigmaDatas.arObject?.file,
        "-arFile-" + enigmaDatas.arObject.file.name
      );
    }
    if (enigmaDatas.arImageRecognition?.imageFile) {
      dataForm.append(
        "imageCollection",
        enigmaDatas.arImageRecognition?.imageFile,
        "-arImageRecognition-" + enigmaDatas.arImageRecognition.imageFile.name
      );
    }
    dataForm.append("enigmaDatas", JSON.stringify(enigmaDatas));

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        // Authorization: authHeader,
      },
    };

    return await apiClient
      .post(`${apiHost}/newEnigma`, dataForm, config)
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response.status,
            data: error.response.data,
          };
        }

        return errorLog;
      });
  }

  static async updateEnigma(enigmaDatas, enigmaId) {
    // const tokens = await JwtUtils.getValidTokens();
    // if (!tokens) {
    //   return { status: null, data: null };
    // }
    // const authHeader = "Bearer " + tokens.accessToken;

    const dataForm = new FormData();

    if (enigmaDatas.clue?.imageFile) {
      dataForm.append(
        "imageCollection",
        enigmaDatas.clue?.imageFile,
        "-clueimage-" + enigmaDatas.clue?.imageFile.name
      );
    }
    if (enigmaDatas.vintage?.imageFile) {
      dataForm.append(
        "imageCollection",
        enigmaDatas.vintage?.imageFile,
        "-vintageimage-" + enigmaDatas.vintage.imageFile.name
      );
    }
    if (enigmaDatas.arObject?.file) {
      dataForm.append(
        "imageCollection",
        enigmaDatas.arObject?.file,
        "-arFile-" + enigmaDatas.arObject.file.name
      );
    }
    if (enigmaDatas.arImageRecognition?.imageFile) {
      dataForm.append(
        "imageCollection",
        enigmaDatas.arImageRecognition?.imageFile,
        "-arImageRecognition-" + enigmaDatas.arImageRecognition.imageFile.name
      );
    }

    dataForm.append("enigmaDatas", JSON.stringify(enigmaDatas));
    dataForm.append("enigmaId", enigmaId);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        // Authorization: authHeader,
      },
    };

    return await apiClient
      .post(`${apiHost}/updateEnigma`, dataForm, config)
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response.status,
            data: error.response.data,
          };
        }

        return errorLog;
      });
  }

  static async updateEnigmaOrdersIds(enigmasDatas) {
    // const tokens = await JwtUtils.getValidTokens();
    // if (!tokens) {
    //   return { status: null, data: null };
    // }
    // const authHeader = "Bearer " + tokens.accessToken;

    return await apiClient
      .post(
        `${apiHost}/updateEnigmasOrderIds`,
        {
          enigmasDatas: enigmasDatas,
        },
        {
          // headers: { Authorization: authHeader },
        }
      )
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response.status,
            data: error.response.data,
          };
        }

        return errorLog;
      });
  }

  static async getEnigmas(zoneId) {
    // const tokens = await JwtUtils.getValidTokens();
    // if (!tokens) {
    //   return { status: null, data: null };
    // }
    // const authHeader = "Bearer " + tokens.accessToken;

    return await apiClient
      .get(`${apiHost}/enigmas`, {
        params: {
          zoneId: zoneId,
        },
        // headers: { Authorization: authHeader },
      })
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response.status,
            data: error.response.data,
          };
        }

        return errorLog;
      });
  }

  static async getEnigma(id) {
    // const tokens = await JwtUtils.getValidTokens();
    // if (!tokens) {
    //   return { status: null, data: null };
    // }
    // const authHeader = "Bearer " + tokens.accessToken;

    return await apiClient
      .get(`${apiHost}/enigma`, {
        params: {
          enigmaId: id,
        },
        // headers: { Authorization: authHeader },
      })
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response.status,
            data: error.response.data,
          };
        }

        return errorLog;
      });
  }

  static async deleteEnigma(id) {
    // const tokens = await JwtUtils.getValidTokens();
    // if (!tokens) {
    //   return { status: null, data: null };
    // }
    // const authHeader = "Bearer " + tokens.accessToken;

    return await apiClient
      .delete(`${apiHost}/enigma`, {
        params: {
          enigmaId: id,
        },
        // headers: { Authorization: authHeader },
      })
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response.status,
            data: error.response.data,
          };
        }

        return errorLog;
      });
  }
}
