import React, { Component } from "react";
import Alert from "@material-ui/lab/Alert";
import { AppBar, Box, Button, Card, CardMedia } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";

const ResetPasswordView = (props) => {
  return (
    <>
      <Box
        className={props.classes.backgroundImg}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <AppBar className={props.classes.appBar}>
          <Card className={props.classes.card}>
            <CardMedia
              component="img"
              alt="Description de l'image"
              height="40"
              width="40"
              image="/logo192.png"
              title="Titre de l'image"
            />
          </Card>
        </AppBar>
        <div>
          <Box
            className={props.classes.container}
            sx={{
              width: 500,
              height: 450,
              bgcolor: "primary.main",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              color: "white",
            }}
          >
            <br />

            <form
              onSubmit={props.changePassword}
              className={props.classes.formContainer}
            >
              <h1>Nouveau mot de passe</h1>
              <div>
                <div>
                  <TextField
                    name="newPassword"
                    variant="outlined"
                    size="small"
                    onChange={props.handleFormChange}
                    label="Nouveau mot de passes"
                    className={props.classes.textfield}
                    type="password"
                  />
                  <br />
                  <TextField
                    name="newPasswordVerify"
                    variant="outlined"
                    size="small"
                    onChange={props.handleFormChange}
                    label="Confirmer le mot de passe"
                    className={props.classes.textfield}
                    type="password"
                  />
                  <br />
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    className={props.classes.validateForm}
                  >
                    Valider
                  </Button>
                  <br />
                  <Link className={props.classes.connect} to="/login">
                    Connexion
                  </Link>
                </div>
              </div>
            </form>
          </Box>
        </div>
        <Box
          sx={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            marginTop: 12,
          }}
        >
          {props.state.showError ? (
            <Alert severity="error">{props.state.errorMessage}</Alert>
          ) : (
            <div />
          )}
          {props.state.showSuccess ? (
            <Alert severity="success">{props.state.successMessage}</Alert>
          ) : (
            <div />
          )}
        </Box>
      </Box>
    </>
  );
};

export default ResetPasswordView;
