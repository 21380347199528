import Axios from "axios";
import { apiHost } from "../constants/ApiConstants";
// import JwtUtils from "../utils/jwtUtils";
import apiClient from "./ApiClient";

export default class ApiPayments {
  // static async activateAccount(sessionId) {
  //   return await apiClient
  //     .post(`${apiHost}/payments/activateAccount`, {
  //       sessionId: sessionId,
  //     })
  //     .then((response) => {
  //       let responseLog = { status: response.status, data: response.data };

  //       return responseLog;
  //     })
  //     .catch((error) => {
  //       let errorLog = {
  //         status: error.response?.status,
  //         data: error.response?.data,
  //       };

  //       return errorLog;
  //     });
  // }

  static async createCheckoutSession(stripePricesKey, userLanguage) {
    return await apiClient
      .get(
        `${apiHost}/payments/createCheckoutSession/${stripePricesKey}/${userLanguage}`
      )
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = {
          status: error.response?.status,
          data: error.response?.data,
        };

        return errorLog;
      });
  }

  static async getSessionStatus(sessionId) {
    return await apiClient
      .get(`${apiHost}/payments/sessionStatus/${sessionId}`)
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = {
          status: error.response?.status,
          data: error.response?.data,
        };

        return errorLog;
      });
  }

  static async cancelSubscription() {
    return await apiClient
      .get(`${apiHost}/payments/cancelSubscription/`)
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = {
          status: error.response?.status,
          data: error.response?.data,
        };

        return errorLog;
      });
  }

  static async getCustomerPortalSession() {
    return await apiClient
      .get(`${apiHost}/payments/stripeCustomerPortal/`)
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = {
          status: error.response?.status,
          data: error.response?.data,
        };

        return errorLog;
      });
  }

  static async getPackInfos() {
    return await apiClient
      .get(`${apiHost}/payments/packsInfos`, {})
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = {
          status: error.response?.status,
          data: error.response?.data,
        };

        return errorLog;
      });
  }
}
