const styles = (theme) => ({
  formContainer: {
    paddingTop: "30px",
    paddingBottom: "30px",
    width: "60ch",
    margin: "auto",
    textAlign: "center",
  },
  textfield: {
    backgroundColor: "white",
    borderRadius: 12,
    marginBottom: 32,
    width: "90%",
  },
  validateForm: {
    textDecoration: "none",
    color: "white",
    fontWeight: 600,
    marginTop: 20,
    marginBottom: 20,
    margin: theme.spacing(1),
    backgroundColor: theme.palette.orange.main,
    "&:hover": {
      backgroundColor: theme.palette.orange.dark,
    },
  },
  facebookButton: {
    marginTop: 20,
    marginBottom: 10,
    textDecoration: "none",
    color: "#4654B9",
    borderColor: "#4654B9",
    fontWeight: 600,
    width: "75%",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },
  googleButton: {
    marginTop: 20,
    textDecoration: "none",
    color: "#E32424",
    borderColor: "#E32424",
    width: "75%",
    fontWeight: 600,
    marginBottom: 10,
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },

  passwordForgotten: {
    color: "#6879ab",
    marginTop: 12,
    textDecoration: "none",
  },
  signup: {
    color: "#6879ab",
    marginTop: 12,
    textDecoration: "none",
  },
  container: {
    padding: 0,
    borderRadius: 22,
    marginTop: 150,
    width: 500,
    maxWidth: "90vw",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    color: "white",
  },
  appBar: {
    backgroundColor: theme.palette.darkGrey.main,
    paddingBottom: 24,
    paddingTop: 24,
  },
  card: {
    marginLeft: 32,
    height: 40,
    width: 40,
  },
  backgroundImg: {
    backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url('/background-login.png')`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
    width: "100%",
  },
  orLogin: {
    // width: "100%",
    paddingLeft: 6,
    paddingRight: 6,
    position: "absolute",
    // marginTop: -20,
    backgroundColor: theme.palette.primary.main,
  },
});

export default styles;
