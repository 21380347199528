import Axios from "axios";

import { apiHost } from "../constants/ApiConstants";
import AuthStorage from "../utils/authStorage";
// import JwtUtils from "../utils/jwtUtils";
import apiClient from "./ApiClient";

export default class ApiAdmin {
  static async signupClientAccount(
    firstName,
    lastName,
    email,
    company,
    address,
    siret,
    phoneNumber,
    role,
    maxNbOfTours,
    maxSessionsPerMonth,
    limitSessionPerMonth
  ) {
    // const tokens = await JwtUtils.getValidTokens();
    // if (!tokens) {
    //   return { status: null, data: null };
    // }

    // const authHeader = "Bearer " + tokens.accessToken;

    return await apiClient
      .post(
        `${apiHost}/admin/signup`,
        {
          firstName: firstName,
          lastName: lastName,
          email: email,
          company: company,
          address: address,
          siret: siret,
          phoneNumber: phoneNumber,
          role: role,
          maxNbOfTours: maxNbOfTours,
          maxSessionsPerMonth: maxSessionsPerMonth,
          limitSessionPerMonth: limitSessionPerMonth,
        }
        // {
        //   headers: { Authorization: authHeader },
        // }
      )
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = {
          status: error.response.status,
          data: error.response.data,
        };

        return errorLog;
      });
  }

  static async getUsersAccounts() {
    // const tokens = await JwtUtils.getValidTokens();
    // if (!tokens) {
    //   return { status: null, data: null };
    // }

    // const authHeader = "Bearer " + tokens.accessToken;

    return await apiClient
      .get(`${apiHost}/admin/allAccounts/`, {
        // headers: { Authorization: authHeader },
      })
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = {
          status: error.response.status,
          data: error.response.data,
        };

        return errorLog;
      });
  }

  static async getUserAccount(accountId) {
    // const tokens = await JwtUtils.getValidTokens();
    // if (!tokens) {
    //   return { status: null, data: null };
    // }

    // const authHeader = "Bearer " + tokens.accessToken;

    return await apiClient
      .get(`${apiHost}/admin/account/${accountId}`, {
        // headers: { Authorization: authHeader },
      })
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response.status,
            data: error.response.data,
          };
        }

        return errorLog;
      });
  }

  static async deleteUserAccount(accountId) {
    // const tokens = await JwtUtils.getValidTokens();
    // if (!tokens) {
    //   return { status: null, data: null };
    // }

    // const authHeader = "Bearer " + tokens.accessToken;

    return await apiClient
      .delete(`${apiHost}/admin/account/${accountId}`, {
        // headers: { Authorization: authHeader },
      })
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response.status,
            data: error.response.data,
          };
        }

        return errorLog;
      });
  }

  static async getToursClient(accountId) {
    // const tokens = await JwtUtils.getValidTokens();
    // if (!tokens) {
    //   return { status: null, data: null };
    // }

    // const authHeader = "Bearer " + tokens.accessToken;

    return await apiClient
      .get(`${apiHost}/admin/clientTours/${accountId}`, {
        // headers: { Authorization: authHeader },
      })
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response.status,
            data: error.response.data,
          };
        }

        return errorLog;
      });
  }

  static async getAccountAnalytics(accountId) {
    // const tokens = await JwtUtils.getValidTokens();
    // if (!tokens) {
    //   return { status: null, data: null };
    // }

    // const authHeader = "Bearer " + tokens.accessToken;

    return await apiClient
      .get(`${apiHost}/admin/allToursAnalytics/${accountId}`, {
        // headers: { Authorization: authHeader },
      })
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response.status,
            data: error.response.data,
          };
        }

        return errorLog;
      });
  }

  static async updateUserAccount(
    userId,
    firstName,
    lastName,
    company,
    address,
    siret,
    phoneNumber,
    maxNbOfTours,
    maxZones,
    maxEnigmas,
    role,
    maxSessionsPerMonth,
    limitSessionPerMonth
  ) {
    // const tokens = await JwtUtils.getValidTokens();
    // if (!tokens) {
    //   return { status: null, data: null };
    // }

    // const authHeader = "Bearer " + tokens.accessToken;

    return await apiClient
      .post(
        `${apiHost}/admin/updateAccount/${userId}`,
        {
          firstName: firstName,
          lastName: lastName,
          company: company,
          address: address,
          siret: siret,
          phoneNumber: phoneNumber,
          maxNbOfTours: maxNbOfTours,
          maxZones: maxZones,
          maxEnigmas: maxEnigmas,
          role: role,
          maxSessionsPerMonth: maxSessionsPerMonth,
          limitSessionPerMonth: limitSessionPerMonth,
        }
        // {
        //   headers: { Authorization: authHeader },
        // }
      )
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response.status,
            data: error.response.data,
          };
        }

        return errorLog;
      });
  }

  static async duplicateAdminTourOnUserAccount(tourId, clientUserId) {
    // const tokens = await JwtUtils.getValidTokens();
    // if (!tokens) {
    //   return { status: null, data: null };
    // }
    // const authHeader = "Bearer " + tokens.accessToken;

    return await apiClient
      .post(
        `${apiHost}/admin/duplicateAdminTourOnUserAccount`,
        {
          tourId: tourId,
        },
        {
          // headers: { Authorization: authHeader },
          params: {
            clientUserId: clientUserId,
          },
        }
      )
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response.status,
            data: error.response.data,
          };
        }

        return errorLog;
      });
  }

  static async uploadClientTour(tourDatas) {
    // const tokens = await JwtUtils.getValidTokens();
    // if (!tokens) {
    //   return { status: null, data: null };
    // }
    // const authHeader = "Bearer " + tokens.accessToken;

    const dataForm = new FormData();

    dataForm.append(
      "imageCollection",
      tourDatas.backgroundImageFile,
      "-backgroundimage-" + tourDatas.backgroundImageFile.name
    );
    if (tourDatas.annotationImageFile)
      dataForm.append(
        "imageCollection",
        tourDatas.annotationImageFile,
        "-annotationimage-" + tourDatas.annotationImageFile.name
      );
    if (tourDatas.inGameBackgroundImageFile)
      dataForm.append(
        "imageCollection",
        tourDatas.inGameBackgroundImageFile,
        "-ingamebackgroundimage-" + tourDatas.inGameBackgroundImageFile.name
      );
    dataForm.append("tourDatas", JSON.stringify(tourDatas));

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    return await apiClient
      .post(
        `${apiHost}/admin/newClientTour`,
        dataForm,
        {
          // headers: { Authorization: authHeader },
          params: {
            clientUserId: tourDatas.clientUserId,
          },
        },
        config
      )
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response.status,
            data: error.response.data,
          };
        }

        return errorLog;
      });
  }

  static async deleteTour(id, clientUserId) {
    // const tokens = await JwtUtils.getValidTokens();
    // if (!tokens) {
    //   return { status: null, data: null };
    // }
    // const authHeader = "Bearer " + tokens.accessToken;

    return await apiClient
      .delete(`${apiHost}/admin/deleteClientTour`, {
        params: {
          tourId: id,
          clientUserId: clientUserId,
        },
        // headers: { Authorization: authHeader },
      })
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response.status,
            data: error.response.data,
          };
        }

        return errorLog;
      });
  }
}
