const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: theme.palette.darkGrey.main,
  },
  link: {
    textDecoration: "none",
  },
  title: {
    paddingLeft: 8,
    fontSize: 24,
    fontWeight: "bold",
  },
  menuButtonUsers: {
    textDecoration: "none",
    margin: theme.spacing(2),
    padding: theme.spacing(1, 2),
    fontSize: "large",
  },
  menuButtonEdit: {
    textDecoration: "none",
    margin: theme.spacing(2),
    padding: theme.spacing(1, 2),
    fontSize: "large",
  },
  container: {
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
});

export default styles;
