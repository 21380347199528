const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: theme.palette.darkGrey.main,
  },
  updateAbo: {
    margin: theme.spacing(1),
    color: "white",
    backgroundColor: "#556aed",
    "&:hover": {
      backgroundColor: "#3b4eca",
    },
  },
  deleteAbo: {
    margin: theme.spacing(1),
    color: "white",
    backgroundColor: "red",
    "&:hover": {
      backgroundColor: "#ac1212",
    },
  },

  title: {
    flexGrow: 1,
  },
  formContainer: {
    paddingTop: "30px",
    paddingBottom: "30px",
    width: "90%",
  },
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "20ch",
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  link: {
    textDecoration: "none",
  },
  textField: {
    minWidth: 200,
    backgroundColor: "white",
  },
  list: {
    backgroundColor: theme.palette.lightGrey.main,
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    borderRadius: 5,
    listStyle: "none",
  },
  buttonListPlus: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 5,
    color: "white",
  },
  returnButtonLink: {
    textDecoration: "none",
    margin: theme.spacing(2),
  },
  danger: {
    color: "red",
  },
  passwordForgotten: {
    textDecoration: "none",
    color: "#6879ab",
    marginLeft: 12,
  },
  saveButton: {
    backgroundColor: theme.palette.orange.main,
    color: "#ffffff",
    fontSize: 16,
    fontWeight: 500,
    borderRadius: 8,
    height: 40,
    marginTop: 9,
    marginBottom: 9,

    "&:hover": {
      backgroundColor: theme.palette.orange.dark,
    },
  },
  deactivateToursButton: {
    margin: theme.spacing(1),
    color: "white",
    backgroundColor: "#556aed",
    "&:hover": {
      backgroundColor: "#3b4eca",
    },
  },
  optionsContainer: {
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },
    [theme.breakpoints.up("md")]: {
      width: "40%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "30%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "20%",
    },
  },
  optionUnlocked: {
    marginLeft: "12px",
    marginBottom: "6px",
    color: "green",
    fontWeight: 300,
    fontSize: "12px",
  },
  banner: {
    color: "#ffffff",
    backgroundColor: theme.palette.orange.main,
    textAlign: "center",
    fontSize: 16,
    fontWeight: 900,
    padding: "15px",
    color: "white",

    // marginBottom: "10px",
  },
  optionLocked: {
    marginLeft: "12px",
    marginBottom: "6px",
    color: "red",
    fontWeight: 300,
    fontSize: "12px",
  },
  accountOptionsText: {
    marginLeft: "12px",
    marginBottom: "4px",
    fontWeight: 500,
    fontSize: "14px",
    display: "flex",
    justifyContent: "space-between",
  },
  reactivateButtonText: {
    marginLeft: "12px",
    marginBottom: "4px",
    fontWeight: 200,
    fontSize: "14px",
  },
});

export default styles;
