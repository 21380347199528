import React from "react";
import Alert from "@material-ui/lab/Alert";

import DraggableList from "../../../../components/draggableList";
import DialogWarning from "../../../../components/dialogWarning";
import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const ParcoursListView = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <DialogWarning
        open={props.state.dialogWaringOpened}
        primaryText={t("parcours_list.delete_confirmation_title")}
        secondaryText={t("parcours_list.delete_confirmation_text")}
        primaryAction={props.deleteTour}
        closeAction={props.closeDeleteTourDialog}
        idItem={props.state.tourIdToDelete}
        primaryButtonText={t("parcours_list.delete_button")}
        secondaryButtonText={t("parcours_list.cancel_button")}
      />
      {props.state.showError ? (
        <Alert severity="error">{props.state.errorMessage}</Alert>
      ) : (
        <div />
      )}
      {props.state.showSuccess ? (
        <Alert severity="success">{props.state.successMessage}</Alert>
      ) : (
        <div />
      )}

      <div
        style={{
          borderRadius: 5,
          listStyle: "none",
          backgroundColor: "#00000000",
        }}
      >
        {props.state.toursLoaded &&
        props.toursItems &&
        props.toursItems.length ? (
          <DraggableList
            items={props.toursItems}
            onDragEnd={props.onDragEnd}
            onDelete={
              props.role === "NO_SUBSCRIBTION"
                ? null
                : props.openDeleteTourDialog
            }
            onDuplicate={
              props.role === "NO_SUBSCRIBTION" ? null : props.duplicateTour
            }
            onEdit={props.onEdit}
            onClick={props.onTourSelection}
            selectedId={props.tourIdSelected}
          />
        ) : (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#00000000",
            }}
          >
            <Typography
              style={{
                color: "white",
                fontWeight: 100,
                backgroundColor: "#00000000",
              }}
            >
              {t("parcours_list.no_tours")}
            </Typography>
          </Box>
        )}
      </div>
    </>
  );
};

export default ParcoursListView;
