import * as React from "react";
import DraggableListItem from "./draggableListItem";
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from "react-beautiful-dnd";
import { Divider } from "@material-ui/core";

const DraggableList = React.memo(
  ({
    items,
    onDragEnd,
    onDelete,
    onDuplicate,
    onEdit,
    onClick,
    selectedId,
  }) => {
    if (!items) {
      return <div>Items: {items}</div>;
    }

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {items.map((item, index) => (
                <>
                  <DraggableListItem
                    item={item}
                    index={index}
                    key={item.id}
                    onDelete={onDelete}
                    onDuplicate={onDuplicate}
                    onEdit={onEdit}
                    onClick={onClick}
                    selectedId={selectedId}
                  />
                  {index !== items.length - 1 && <Divider />}
                </>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
);

export default DraggableList;
