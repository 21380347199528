const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  list: {
    borderRadius: 5,
    listStyle: "none",
    backgroundColor: "#000000",
  },
});

export default styles;
