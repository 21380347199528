import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isSameOrBefore);

export default class MapTilesChartUtils {
  // Fonction pour formater les données pour le graphique en lignes
  static formatDataForLineChart = (data, startDate, endDate) => {
    console.log("start date ", startDate);
    console.log("end date ", endDate);
    // Créer un objet pour stocker les données cumulatives par date pour chaque tour (parcours)
    const cumulativeData = {};
    const toursMap = {};

    data.forEach((tour) => {
      const { tourName, tilesRequests } = tour;

      // Créer un tableau vide pour chaque tour dans l'objet toursMap
      toursMap[tourName] = {};

      tilesRequests.forEach((tile) => {
        const dateKey = dayjs(tile.requestAt)
          .startOf("day")
          .format("YYYY-MM-DD"); // Regrouper par jour

        // Incrémenter le nombre de requêtes pour ce tour à cette date
        if (!toursMap[tourName][dateKey]) {
          toursMap[tourName][dateKey] = 0;
        }
        toursMap[tourName][dateKey] += 1;

        // Cumuler les requêtes globales pour ce jour dans cumulativeData
        if (!cumulativeData[dateKey]) {
          cumulativeData[dateKey] = 0;
        }
        cumulativeData[dateKey] += 1;
      });
    });

    // Créer les labels à partir des dates cumulées
    const labels = [];
    if (startDate && endDate) {
      let currentDate = new Date(startDate);
      endDate.setMinutes(startDate.getMinutes());
      endDate.setHours(startDate.getHours());
      while (currentDate <= endDate) {
        // Ajouter la date au format YYYY-MM-DD
        labels.push(currentDate.toISOString().split("T")[0]);

        // Avancer d'un jour
        currentDate.setDate(currentDate.getDate() + 1);
      }
      console.log("currentDate", currentDate);
      console.log("endDate", endDate);
    }
    // Créer les datasets pour chaque tour
    const datasets = Object.keys(toursMap).map((tourName) => {
      const dataPoints = labels?.map((label) => toursMap[tourName][label] || 0); // Remplir les jours où il n'y a pas de requêtes avec 0

      return {
        label: tourName, // Le nom du tour sera le label de la ligne
        data: dataPoints, // Les points de données pour chaque jour
        fill: false,
        borderColor: this.getRandomColor(), // Assigner une couleur aléatoire pour chaque ligne
      };
    });

    console.log("labels ", labels);
    console.log("datasets ", datasets);

    // Retourner les données au format attendu par Chart.js
    return {
      labels, // Les dates comme labels de l'axe X
      datasets, // Les datasets (une ligne par tour)
    };
  };

  // Fonction utilitaire pour générer des couleurs aléatoires pour chaque ligne
  static getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
}
