import React from "react";

class QrCodeStoresComponents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href =
        "https://apps.apple.com/fr/app/landing-zone/id6472918772";
    } else if (/android/i.test(userAgent)) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.alpagapp.landingzone";
    } else if (/Mac/i.test(userAgent)) {
      window.location.href =
        "https://apps.apple.com/fr/app/landing-zone/id6472918772";
    } else {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.alpagapp.landingzone";
    }
  }

  render() {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <h1>Redirection vers le store</h1>
        </div>
      </>
    );
  }
}

export default QrCodeStoresComponents;
