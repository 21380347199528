import React, { Component } from "react";
import { Redirect, useHistory, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import styles from "./checkYourMailsStyles";
import CheckYourMailsView from "./checkYourMailsView";

class CheckYourMails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: false,
      errorMessage: "",
      showSuccess: false,
      successMessage: "",
    };
  }

  componentDidMount() {
    document.title = "Vérifiez vos mails | Landing Zone";
  }

  render() {
    const { classes } = this.props;

    if (this.state.islogged) {
      return <Redirect to="/" />;
    }

    return (
      <>
        <CheckYourMailsView
          {...this.props}
          state={this.state}
          classes={classes}
        />
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(CheckYourMails)
);
