import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const DialogWarning = ({
  primaryText,
  secondaryText,
  primaryAction,
  closeAction,
  idItem,
  open,
  primaryButtonText,
  secondaryButtonText,
}) => {
  return (
    <Dialog
      open={open}
      onClose={closeAction}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{primaryText}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {secondaryText.split("\\n").map((text, index) => (
            <React.Fragment key={index}>
              {text}
              <br />
            </React.Fragment>
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="#ffffff"
          backgroundColor="#841584"
          onClick={() => {
            primaryAction(idItem);
          }}
        >
          {primaryButtonText}
        </Button>
        <Button
          color="primary"
          onClick={() => {
            closeAction();
          }}
          autoFocus
        >
          {secondaryButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogWarning;
