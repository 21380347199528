export const GameMode = {
  MAP_AND_QRCODE: 0,
  ONLY_QRCODE: 1,
  AUTOMATIC_LAUNCH: 2,
};

export const ZoneMode = {
  MAP: 0,
  QRCODE: 1,
  AUTOMATIC_LAUNCH: 2,
};

export const EnigmaType = {
  TEXT: 0,
  PICKER: 1,
  COMPASS: 2,
  SWITCH: 3,
  POTENTIOMETER: 4,
};
