import React from "react";
import { withStyles } from "@material-ui/core/styles";
import qs from "qs";

import ApiUser from "../../../API/ApiUser";
import ApiTour from "../../../API/ApiTour";

import styles from "./tourSelectedStyles";
import TourSelectedView from "./tourSelectedView";

class TourSelected extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tourId: 9999,
      title: "",
    };
  }

  componentDidMount() {
    let id = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).id;

    id = parseInt(id);
    this.setState((prevState) => ({
      ...prevState,
      tourId: id,
    }));

    this.setStateIsAdmin();

    ApiTour.getTour(id).then((tourDatas) => {
      if (!tourDatas || tourDatas.status !== 200) {
        this.setState((prevState) => ({
          ...prevState,
          showError: true,
          errorMessage:
            "Une erreur est survenue lors de la récupération du circuit",
          showSuccess: false,
          successMessage: "",
        }));

        return;
      }
      this.setState((prevState) => ({
        ...prevState,
        editing: true,
        title: tourDatas.data.title,
      }));
    });
  }

  async setStateIsAdmin() {
    let response = await ApiUser.isAdmin();

    if (response.status == 200) {
      this.setState((prevState) => ({
        ...prevState,
        isAdmin: response.data.isAdmin,
      }));
    }
  }

  render() {
    return <TourSelectedView {...this.props} state={this.state} />;
  }
}

export default withStyles(styles, { withTheme: true })(TourSelected);
