const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  body: {
    "& tr": {
      borderBottom: "solid 1px red",
    },
  },
  fontSizeAdaptativeTitle: {
    [theme.breakpoints.up("xs")]: {
      fontSize: "20px !important", // plus petit écran
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "22px !important", // écrans moyens
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "24px !important", // écrans larges
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "26px !important", // très grands écrans
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "28px !important", // extra grands écrans
    },
  },
  fontSizeAdaptative: {
    [theme.breakpoints.up("xs")]: {
      fontSize: "14px !important", // plus petit écran
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px !important", // écrans moyens
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "18px !important", // écrans larges
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "20px !important", // très grands écrans
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "20px !important", // extra grands écrans
    },
  },
  list: {
    backgroundColor: theme.palette.lightGrey.main,
    margin: theme.spacing(5),
    borderRadius: 5,
    listStyle: "none",
  },
  addCodeButton: {
    backgroundColor: theme.palette.orange.main,
    borderRadius: 12,
    color: "#ffffff",
    marginBottom: 32,
    marginTop: 12,
    fontWeight: 900,
    fontSize: 16,
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.orange.dark,
    },
  },
  searchInput: {
    backgroundColor: "white",
    borderRadius: 12,
    marginBottom: 32,
    width: "40%",
  },
  tableLeft: {
    backgroundColor: theme.palette.primary.main,
    height: "calc(100vh - 119px)",
    marginLeft: "12px",
  },
  tableRight: {
    backgroundColor: theme.palette.primary.main,
    height: "calc(50vh - 95px)",
  },
  tableCellHeader: {
    color: "#ffffff",
    fontWeight: 600,
    fontSize: 14,
    borderBottom: "solid 1px #768FDD88",
    backgroundColor: theme.palette.darkGrey.main,
  },
  tableCell: {
    color: "#ffffff",
    borderBottom: "solid 1px #768FDD88",
  },
  firstPlace: {
    display: "inline-block",
    width: "30px",
    height: "30px",
    backgroundColor: "gold",
    color: "black",
    borderRadius: "50%",
    textAlign: "center",
    lineHeight: "30px",
  },
  scndPlace: {
    display: "inline-block",
    width: "30px",
    height: "30px",
    backgroundColor: "silver",
    color: "black",
    borderRadius: "50%",
    textAlign: "center",
    lineHeight: "30px",
  },
  thrdPlace: {
    display: "inline-block",
    width: "30px",
    height: "30px",
    backgroundColor: "#CD7F32",
    color: "black",
    borderRadius: "50%",
    textAlign: "center",
    lineHeight: "30px",
  },
  otherPlace: {
    marginLeft: "10px",
  },
  alreadySentMailButton: {
    color: "#ffffff",
    backgroundColor: theme.palette.green.main,
    borderRadius: 12,
    textTransform: "none",
  },
  sendMailButton: {
    color: "#ffffff",
    backgroundColor: theme.palette.orange.main,
    borderRadius: 12,
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.orange.dark,
    },
  },
  disabledSendMailButton: {
    color: "#ffffffff",
    backgroundColor: "#F1DBC5",
    borderRadius: 12,
    textTransform: "none",
  },
  textField: {
    backgroundColor: "white",
    borderRadius: 8,
    marginBottom: 32,
    width: "90%",
  },
  customSortLabel: {
    color: "white !important",
    "& .MuiTableSortLabel-icon": {
      color: "white !important",
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px",
    boxSizing: "border-box",
  },
  leftBox: {
    backgroundColor: "lightblue",
    flex: 1,
    width: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  topRightBox: {
    backgroundColor: "lightgreen",
    width: "50%",
    height: "50%",
  },
  bottomRightBox: {
    backgroundColor: "lightcoral",
    width: "50%",
    height: "50%",
  },
});

export default styles;
