const styles = (theme) => ({
  formContainer: {
    paddingTop: "30px",
    paddingBottom: "30px",
    width: "60ch",
    margin: "auto",
    textAlign: "center",
  },
  textfield: {
    minWidth: 200,
    margin: theme.spacing(1),
    backgroundColor: "white",
    borderRadius: 12,
    marginBottom: 32,
    width: "90%",
  },
  validateForm: {
    textDecoration: "none",
    color: "white",
    fontWeight: 600,
    marginTop: 20,
    marginBottom: 20,
    margin: theme.spacing(1),
    backgroundColor: theme.palette.orange.main,
    "&:hover": {
      backgroundColor: theme.palette.orange.dark,
    },
  },
  connect: {
    color: "#6879ab",
    margin: theme.spacing(1),
    textDecoration: "none",
  },
  container: {
    padding: 30,
    borderRadius: 22,
  },
  appBar: {
    backgroundColor: theme.palette.darkGrey.main,
    paddingBottom: 24,
    paddingTop: 24,
  },
  card: {
    marginLeft: 32,
    height: 40,
    width: 40,
  },
  backgroundImg: {
    backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url('/background-login.png')`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
});

export default styles;
