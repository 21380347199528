import { Bar, Line } from "react-chartjs-2";
import "chartjs-adapter-date-fns";

const options = {
  plugins: {
    title: {
      display: true,
      text: "Requêtes de tuiles",
      padding: {
        top: 10,
        bottom: 30,
      },
    },
  },
  scales: {
    x: {
      type: "time", // Type de l'axe X, ici des données temporelles
      time: {
        tooltipFormat: "dd/MM/yyyy", // Format de l'info-bulle des dates
        unit: "day", // Unité de temps (jours)
      },
      title: {
        display: true,
        text: "Date",
      },
    },
    y: {
      title: {
        display: true,
        text: "Requêtes",
      },
      beginAtZero: true, // Commencer l'axe Y à zéro
    },
  },
};

export const MapTilesStats = ({ lineData }) => {
  return (
    <div>
      <Line data={lineData} options={options} />
    </div>
  );
};
