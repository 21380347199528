import React, { useState } from "react";
import * as moment from "moment";

import { withStyles } from "@material-ui/core/styles";
import MapIcon from "@material-ui/icons/Map";

import ApiTour from "../../../../../API/ApiTour";
import styles from "./codeListStyles";
import CodeListView from "./codeListView";
import ApiUser from "../../../../../API/ApiUser";

moment.locale("fr");

class CodeListComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tourCodes: [],
      dialogOpen: false,
      searchTerm: "",
      page: 0,
      rowPerPage: 10,
      totalRows: 0,
      leaderboardActivated: false,
      tourDisabled: null,
    };

    this.setLeaderboardActivated();

    this.handleOpenAddCodeDialog = this.handleOpenAddCodeDialog.bind(this);
    this.handleCloseAddCodeDialog = this.handleCloseAddCodeDialog.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.formatDate = this.formatDate.bind(this);
    this.getStatut = this.getStatut.bind(this);
    this.isMailSent = this.isMailSent.bind(this);
    this.sendMail = this.sendMail.bind(this);
    this.archiveTourCode = this.archiveTourCode.bind(this);
    this.onOpenStats = this.onOpenStats.bind(this);

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }

  componentDidMount() {
    this.getTourCodes(this.props.tourId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.tourId !== prevProps.tourId) {
      this.getTourCodes(this.props.tourId);
    }
  }

  handleOpenAddCodeDialog = () => {
    this.setState((prevState) => ({
      ...prevState,
      dialogOpen: true,
    }));
  };

  handleCloseAddCodeDialog = () => {
    this.getTourCodes(this.props.tourId);
    this.setState((prevState) => ({
      ...prevState,
      dialogOpen: false,
    }));
  };

  onOpenStats = (tourId) => {
    this.props.history.push(`/statistiques?tourId=${tourId}`);
  };

  async setLeaderboardActivated() {
    let response = await ApiUser.getAccount();

    if (response.status == 200) {
      this.setState((prevState) => ({
        ...prevState,
        leaderboardActivated:
          response.data.role !== "PACK_DECOUVERTE" &&
          response.data.role !== "NO_SUBSCRIBTION",
      }));
    }
  }

  async getIsTourDisabled(tourId) {
    console.log("getIsTourDisabled");
    if (!tourId || tourId === -1) {
      return;
    }
    ApiTour.getTour(tourId).then((tourRes) => {
      console.log("tourRes.data.disabled ", tourRes.data.disabled);
      if (tourRes.status === 200) {
        this.setState((prevState) => ({
          ...prevState,
          tourDisabled: tourRes.data.disabled,
        }));
      }
    });
  }

  async getTourCodes(tourId) {
    if (!tourId || tourId === -1) {
      return;
    }
    this.getIsTourDisabled(this.props.tourId);
    ApiTour.getTourCodes(
      tourId,
      this.state.searchTerm,
      this.state.page,
      this.state.rowPerPage
    ).then((tourCodes) => {
      if (!tourCodes.data || tourCodes.status !== 200) {
        return;
      }

      this.setState(
        (prevState) => ({
          ...prevState,
          tourCodes: tourCodes.data.items,
          totalRows: tourCodes.data.total,
        }),
        () => {}
      );
    });
  }

  handleChangePage = (event, newPage) => {
    this.setState(
      (preveState) => ({
        ...preveState,
        page: newPage,
      }),
      () => {
        this.getTourCodes(this.props.tourId);
      }
    );
  };

  handleChangeRowsPerPage = (event) => {
    this.setState(
      (preveState) => ({
        ...preveState,
        rowPerPage: +event.target.value,
        page: 0,
      }),
      () => {
        this.getTourCodes(this.props.tourId);
      }
    );
  };

  handleSearchChange = (event) => {
    this.setState(
      (preveState) => ({
        ...preveState,
        searchTerm: event.target.value,
        page: 0,
      }),
      () => {
        this.getTourCodes(this.props.tourId);
      }
    );
  };

  formatDate = (date) => {
    if (!date) {
      return "";
    }

    return moment(date).format("ddd DD/MM/YYYY");
  };

  formatDateShort = (date) => {
    if (!date) {
      return "";
    }

    return moment(date).format("DD/MM/YYYY");
  };

  getStatut = (endDate, usageDate, isTest) => {
    const currentDate = moment();
    const formattedEndDate = moment(endDate);

    if (formattedEndDate.isBefore(currentDate) && !usageDate) {
      return "Expiré";
    } else if (usageDate && isTest != true) {
      return "Utilisé";
    }
    return "À utiliser";
  };

  isMailSent = (code) => {
    if (code && !code.emailSent) {
      return false;
    }
    return true;
  };

  sendMail = (codeId) => {
    ApiTour.sendEmailNewCode(codeId).then((res) => {
      this.getTourCodes(this.props.tourId);
    });
  };

  archiveTourCode = async (codeId) => {
    ApiTour.archiveTourCode(codeId).then((res) => {
      if (res.status === 200) {
        this.getTourCodes(this.props.tourId);
      }
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        <CodeListView
          state={this.state}
          role={this.props.role}
          tourCodes={this.state.tourCodes}
          classes={classes}
          tourId={this.props.tourId}
          handleOpenAddCodeDialog={this.handleOpenAddCodeDialog}
          handleCloseAddCodeDialog={this.handleCloseAddCodeDialog}
          dialogOpen={this.state.dialogOpen}
          handleSearchChange={this.handleSearchChange}
          formatDate={this.formatDate}
          getStatut={this.getStatut}
          isMailSent={this.isMailSent}
          sendMail={this.sendMail}
          searchTerm={this.state.searchTerm}
          archiveTourCode={this.archiveTourCode}
          formatDateShort={this.formatDateShort}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          onOpenStats={this.onOpenStats}
          tourDisabled={this.state.tourDisabled}
        />
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(CodeListComponent);
