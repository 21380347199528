import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Map,
  CropFree,
  LocationOn,
  Category,
  NavigateNext,
} from "@material-ui/icons";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { ZoneMode } from "../../constants/TourConstants";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles({
  breadcrumb: {
    color: "#232C48",
    "&:hover": {
      color: "#4B5679",
      textDecoration: "none",
    },
    textDecoration: "none",
  },
  breadcrumbLast: {
    color: "#ffffff",
    backgroundColor: "#F48A21",
    borderRadius: 6,
    padding: 6,
    textDecoration: "none",
  },
  breadcrumbInactive: {
    color: "#232C4888",
    textDecoration: "none",
  },
  icon: {
    verticalAlign: "-2px",
  },
});

function BreadcrumbTour({ tourId, zoneId, enigmaId, zoneMode }) {
  const classes = useStyles();

  const BreadcrumbLink = ({ to, isLast, children }) => (
    <Link
      color="inherit"
      component={RouterLink}
      to={to}
      className={isLast ? classes.breadcrumbLast : classes.breadcrumb}
    >
      {children}
    </Link>
  );

  const BreadcrumbText = ({ children }) => (
    <Typography color="textSecondary" className={classes.breadcrumbInactive}>
      {children}
    </Typography>
  );

  const isLast = (type) => {
    if (type === "tour" && !zoneId) {
      return true;
    }
    if (type === "zone" && zoneId && !enigmaId) {
      return true;
    }
    if (type === "enigma" && enigmaId) {
      return true;
    }
  };

  return (
    <>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNext fontSize="small" />}
      >
        {tourId ? (
          <BreadcrumbLink isLast={isLast("tour")} to={`/parcours?id=${tourId}`}>
            <Map fontSize="inherit" className={classes.icon}></Map> Jeu
          </BreadcrumbLink>
        ) : (
          <BreadcrumbText>
            <Map fontSize="inherit" className={classes.icon}></Map> Jeu
          </BreadcrumbText>
        )}

        {zoneId ? (
          <BreadcrumbLink
            isLast={isLast("zone")}
            to={`/zone?tourId=${tourId}&zoneId=${zoneId}`}
          >
            {zoneMode === ZoneMode.QRCODE ? (
              <CropFree fontSize="inherit" className={classes.icon}></CropFree>
            ) : (
              <LocationOn
                fontSize="inherit"
                className={classes.icon}
              ></LocationOn>
            )}{" "}
            Zone
          </BreadcrumbLink>
        ) : (
          <BreadcrumbText>
            {zoneMode === ZoneMode.QRCODE ? (
              <CropFree fontSize="inherit" className={classes.icon}></CropFree>
            ) : (
              <LocationOn
                fontSize="inherit"
                className={classes.icon}
              ></LocationOn>
            )}{" "}
            Zone
          </BreadcrumbText>
        )}

        {enigmaId ? (
          <BreadcrumbLink
            isLast={isLast("enigma")}
            to={`/enigme?tourId=${tourId}&zoneId=${zoneId}&enigmaId=${enigmaId}`}
          >
            <Category fontSize="inherit" className={classes.icon}></Category>{" "}
            Énigme
          </BreadcrumbLink>
        ) : (
          <BreadcrumbText>
            <Category fontSize="inherit" className={classes.icon}></Category>{" "}
            Énigme
          </BreadcrumbText>
        )}
      </Breadcrumbs>
      <Divider style={{ marginTop: 18, marginBottom: 18 }}></Divider>
    </>
  );
}

export default BreadcrumbTour;
