import React, { Component } from "react";
import { Redirect, useHistory, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import { withTranslation } from "react-i18next";

import AuthStorage from "../../../utils/authStorage";
import ApiUser from "../../../API/ApiUser";
import styles from "./loginStyles";
import LoginView from "./loginView";
import qs from "qs";
import { apiHost } from "../../../constants/ApiConstants";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: false,
      errorMessage: "",
      showSuccess: false,
      successMessage: "",
      islogged: false,
      loginParams: {
        user_email: "",
        user_password: "",
      },
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.getGoogleLoginUrl = this.getGoogleLoginUrl.bind(this);
    this.getPlatform = this.getPlatform.bind(this);
    this.login = this.login.bind(this);
  }

  componentDidMount() {
    const { t } = this.props;
    document.title = "Connexion | Landing Zone";

    let verifyToken = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).verifyToken;
    if (verifyToken) {
      ApiUser.verifyAccount(verifyToken).then((response) => {
        if (response.status == 200) {
          this.setState({
            showSuccess: true,
            successMessage: t("login.account_validated"),
          });
        }
      });
    }
  }

  getPlatform() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return "android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "ios";
    }

    return "other";
  }

  // Fonction pour construire l'URL de connexion
  getGoogleLoginUrl() {
    const oauthUrl = `${apiHost}/user/signinGoogle`; // Remplacez par votre URL OAuth complète si nécessaire
    const platform = this.getPlatform();
    console.log("platform ", platform);

    if (platform === "android") {
      // Construire l'Intent URL pour Android
      // Assurez-vous que l'URL OAuth est en HTTPS
      return `intent://${oauthUrl.replace(
        /^https?:\/\//,
        ""
      )}#Intent;scheme=https;package=com.android.chrome;end`;
    } else if (platform === "ios") {
      // Utiliser l'URL OAuth standard pour iOS
      // Assurez-vous que les Universal Links sont configurés correctement
      return oauthUrl;
    } else {
      // Pour les autres plateformes (Desktop), utiliser l'URL standard
      return oauthUrl;
    }
  }

  handleFormChange(event) {
    let loginParamsNew = { ...this.state.loginParams };
    let val = event.target.value;
    loginParamsNew[event.target.name] = val;

    this.setState({
      loginParams: loginParamsNew,
    });
  }

  async login(event) {
    const { t } = this.props;
    event.preventDefault();
    let user_email = this.state.loginParams.user_email;
    let user_password = this.state.loginParams.user_password;

    let response = await ApiUser.signin(user_email, user_password);

    if (response.status == 200) {
      this.setState({
        islogged: true,
      });
      const token = response.data.token;

      // Stocker le token (par exemple, dans le localStorage ou le state parent)
      localStorage.setItem("token", token);

      // Configurer l'en-tête Authorization pour les futures requêtes
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      this.setState((prevState) => ({
        ...prevState,
        errorMessage: t("login.incorrect_password"),
        showError: true,
      }));
    }
  }

  render() {
    const { classes } = this.props;

    if (this.state.islogged) {
      return <Redirect to="/" />;
    }

    return (
      <>
        <LoginView
          {...this.props}
          state={this.state}
          handleFormChange={this.handleFormChange}
          login={this.login}
          getGoogleLoginUrl={this.getGoogleLoginUrl}
          getPlatform={this.getPlatform}
        />
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(withTranslation()(Login))
);
