import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MapIcon from "@material-ui/icons/Map";
import axios from "axios";

import ApiTour from "../../../API/ApiTour";
import ApiUser from "../../../API/ApiUser";
import styles from "./homeStyles";
import HomeView from "./homeView";
import qs from "qs";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      isAdmin: false,
      tourIdSelected: -1,
    };

    this.onTourSelection = this.onTourSelection.bind(this);
  }

  componentDidMount() {
    document.title = "Parcours | Landing Zone";

    this.getJWTTokenFromGoogleIfExists();
    this.setStateIsAdmin();
    this.getUser();
  }

  getJWTTokenFromGoogleIfExists() {
    let token = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).token;

    console.log("token ", token);

    if (token) {
      // Stocker le token dans le localStorage
      localStorage.setItem("token", token);

      // Configurer l'en-tête Authorization pour les futures requêtes
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
  }

  async getUser() {
    let response = await ApiUser.getAccount();

    if (response.status == 200) {
      console.log("user : ", response.data);
      this.setState((prevState) => ({
        ...prevState,
        user: response.data,
      }));
    }
  }

  async setStateIsAdmin() {
    let response = await ApiUser.isAdmin();

    if (response.status == 200) {
      this.setState((prevState) => ({
        ...prevState,
        isAdmin: response.data.isAdmin,
      }));
    }
  }

  async onTourSelection(tourId) {
    this.setState((prevState) => ({
      ...prevState,
      tourIdSelected: tourId,
    }));
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <HomeView
          {...this.props}
          classes={classes}
          role={this.state.user?.role}
          hasTrial={this.state.user?.hasTrial}
          state={this.state}
          onTourSelection={this.onTourSelection}
        />
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Home);
