import React, { useState, useEffect } from "react";
import { Circle, GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import { Container } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const SelectLocationDialog = (props) => {
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [zonePosition, setZonePosition] = useState({
    lat: 0,
    lng: 0,
  });
  const [currentPosition, setCurrentPosition] = useState({
    lat: 0,
    lng: 0,
  });
  const [userDeniedLocation, setUserDeniedLocation] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (props.initLocationLat && props.initLocationLng) {
      setUserDeniedLocation(false);
      return;
    }
    console.log("props.lat 1 ", props.lat);

    navigator.geolocation.getCurrentPosition(
      (position) => {
        if (props.tourLocationLat && props.tourLocationLng) {
          console.log("--1--");
          setUserDeniedLocation(false);
          setCurrentPosition({
            lat: props.tourLocationLat,
            lng: props.tourLocationLng,
          });
        } else if (props.lat && props.lng) {
          console.log("--2--");
          setUserDeniedLocation(false);
          setCurrentPosition({
            lat: props.lat,
            lng: props.lng,
          });
        } else if (
          !props.initLocationLat &&
          !props.initLocationLng &&
          !props.lat &&
          !props.lng
        ) {
          console.log("--3--");
          setUserDeniedLocation(false);
          setCurrentPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        }
      },
      (error) => {
        setUserDeniedLocation(true);
        setCurrentPosition({
          lat: 48.855915,
          lng: 2.318608,
        });
      },
      { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
    );
  }, []);

  useEffect(() => {
    if (props.initLocationLat && props.initLocationLng) {
      setUserDeniedLocation(false);
      setCurrentPosition({
        lat: props.initLocationLat,
        lng: props.initLocationLng,
      });
    }
  }, [props.initLocationLat, props.initLocationLng]);

  useEffect(() => {
    if (props.lat && props.lng) {
      setSelectedPosition({
        lat: props.lat,
        lng: props.lng,
      });
      setCurrentPosition({
        lat: props.lat,
        lng: props.lng,
      });
    }
  }, [props.lat, props.lng]);

  useEffect(() => {
    console.log("props.zoneLat ", props.zoneLat);
    if (props.zoneLat && props.zoneLng) {
      setZonePosition({
        lat: props.zoneLat,
        lng: props.zoneLng,
      });
    }
  }, [props.zoneLat, props.zoneLng]);

  const onSelectInZone = (e) => {
    const newLat = e.latLng.lat();
    const newLng = e.latLng.lng();

    const zoneLat = zonePosition.lat;
    const zoneLng = zonePosition.lng;
    const zoneRadius = props.zoneRadius;

    // Fonction pour calculer la distance entre deux points
    const calculateDistance = (lat1, lng1, lat2, lng2) => {
      const toRadians = (degree) => degree * (Math.PI / 180);
      const R = 6371e3; // Rayon de la Terre en mètres
      const φ1 = toRadians(lat1);
      const φ2 = toRadians(lat2);
      const Δφ = toRadians(lat2 - lat1);
      const Δλ = toRadians(lng2 - lng1);

      const a =
        Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      const distance = R * c; // Distance en mètres
      return distance;
    };

    if (zoneLat && zoneLng && zoneRadius) {
      const distance = calculateDistance(zoneLat, zoneLng, newLat, newLng);
      if (distance <= zoneRadius) {
        setSelectedPosition({
          lat: newLat,
          lng: newLng,
        });
      }
    }
  };

  const onSelect = (e) => {
    if (props.type === "AR_OBJECT") {
      onSelectInZone(e);
    } else {
      setSelectedPosition({
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      });
    }
  };

  const handleClose = (saveLocation) => {
    if (selectedPosition && saveLocation) {
      props.onLocationSelected(selectedPosition, props.type);
    } else if (props.lat && props.lng) {
      setSelectedPosition({
        lat: props.lat,
        lng: props.lng,
      });
    }
    props.onClose();
  };

  return (
    <Dialog
      open={props.open ?? false}
      onClose={() => handleClose(false)}
      fullWidth
      maxWidth="md"
    >
      {userDeniedLocation ? (
        <Container
          style={{
            backgroundColor: "white",
            color: "red",
            textAlign: "center",
          }}
        >
          {t("map.no_access_to_location")}
        </Container>
      ) : (
        <></>
      )}
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
        <GoogleMap
          center={currentPosition}
          zoom={13}
          onClick={onSelect}
          options={{
            scaleControl: true,
          }}
          mapContainerStyle={{ height: "800px", width: "100%" }}
        >
          {selectedPosition && <Marker position={selectedPosition} />}
          {selectedPosition &&
            props.zoneRadius &&
            props.type !== "AR_OBJECT" && (
              <Circle
                center={selectedPosition}
                radius={props.zoneRadius}
                options={{
                  strokeColor: "#FF0000",
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: "#FF0000",
                  fillOpacity: 0.35,
                  clickable: false,
                  draggable: false,
                  editable: false,
                  visible: true,
                  zIndex: 1,
                }}
              />
            )}
          {zonePosition.lat &&
            zonePosition.lng &&
            props.zoneRadius &&
            props.type === "AR_OBJECT" && (
              <Circle
                center={zonePosition}
                radius={props.zoneRadius}
                options={{
                  strokeColor: "#FF0000",
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: "#FF0000",
                  fillOpacity: 0.35,
                  clickable: false,
                  draggable: false,
                  editable: false,
                  visible: true,
                  zIndex: 1,
                }}
              />
            )}
        </GoogleMap>
      </LoadScript>
      <Button
        style={{ backgroundColor: "#F48A21", color: "white" }}
        onClick={() => handleClose(true)}
      >
        {t("map.select_this_location")}
      </Button>
    </Dialog>
  );
};

export default SelectLocationDialog;
