const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  list: {
    backgroundColor: theme.palette.lightGrey.main,
    borderRadius: 5,
    listStyle: "none",
  },
  pannel: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 8,
  },
  banner: {
    color: "#ffffff",
    backgroundColor: theme.palette.orange.main,
    textAlign: "center",
    fontSize: 16,
    fontWeight: 900,
    padding: "15px",
    color: "white",

    // marginBottom: "10px",
  },
});

export default styles;
