import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import TextField from "@material-ui/core/TextField";
import { Redirect } from "react-router-dom";
import AccountIcon from "@material-ui/icons/AccountCircle";
import List from "@material-ui/core/List";
import DoneIcon from "@material-ui/icons/Done";
import MapIcon from "@material-ui/icons/Map";

import ApiAdmin from "../../API/ApiAdmin";
import ApiUser from "../../API/ApiUser";
import DraggableList from "../components/draggableList";
import ListItemLink from "../components/listItemLink";
import DialogWarning from "../components/dialogWarning";
import Authenticated from "../components/authenticated";
import AuthStorage from "../../utils/authStorage";
import MainAppBar from "../components/MainAppBar";

import { apiHost } from "../../constants/ApiConstants";
import { Box, Grid } from "@material-ui/core";
import ApiGameAnalytics from "../../API/ApiGameAnalytics";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  list: {
    backgroundColor: theme.palette.lightGrey.main,
    margin: theme.spacing(5),
    borderRadius: 5,
    listStyle: "none",
  },
  pannel: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
    marginRight: 12,
  },
});

class ManageAccounts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      totalTilesRequestsCurrentMonth: null,
      totalTilesRequestsPreviousMonth: null,
      totalTilesRequestsThwoMonthAgo: null,
      isAdmin: false,
      isAdminSet: false,
      showError: false,
      errorMessage: "",
      showSuccess: false,
      successMessage: "",
      accountsLoaded: false,
      dialogWaringOpened: false,
      userIdToDelete: -1,
      accounts: [
        {
          company: "",
          email: "",
          createdAt: "",
          id: 0,
          role: "",
          createdAtTime: 0,
        },
      ],
    };

    this.onDragEnd = this.onDragEnd.bind(this);

    this.deleteAccount = this.deleteAccount.bind(this);
    this.openDeleteAccountDialog = this.openDeleteAccountDialog.bind(this);
    this.closeDeleteAccountDialog = this.closeDeleteAccountDialog.bind(this);
  }

  componentDidMount() {
    document.title = "Comptes clients | Landing Zone";

    this.setStateIsAdmin();
    this.getAccounts();
    this.getTotalTilesRequests();
  }

  async getTotalTilesRequests() {
    const res = await ApiGameAnalytics.getMapTilesRequestsTotalThreeMonth();
    console.log("res.data ", res.data);
    if (res.status === 200) {
      this.setState((prevState) => ({
        ...prevState,
        totalTilesRequestsCurrentMonth: res.data.currentMonth,
        totalTilesRequestsPreviousMonth: res.data.previousMonth,
        totalTilesRequestsThwoMonthAgo: res.data.twoMonthsAgo,
      }));
    }
  }

  async deleteAccount(userId) {
    ApiAdmin.deleteUserAccount(userId).then((response) => {
      if (response.status != 200) {
        this.setState((prevState) => ({
          ...prevState,
          showError: true,
          showSuccess: false,
          successMessage: "Le profil n'a pas pu être supprimé",
        }));
        return;
      }

      let newAccounts = this.state.accounts;
      this.state.accounts.forEach((account, index) => {
        if (account.id == userId) {
          newAccounts.splice(index, 1);
        }
      });

      this.setState((prevState) => ({
        ...prevState,
        accounts: newAccounts,
        showError: false,
        showSuccess: true,
        dialogWaringOpened: false,
        successMessage: "Le profile à bien été supprimé",
      }));
    });
  }

  openDeleteAccountDialog(userId) {
    this.setState((prevState) => ({
      ...prevState,
      userIdToDelete: userId,
      dialogWaringOpened: true,
    }));
  }

  closeDeleteAccountDialog() {
    this.setState((prevState) => ({
      ...prevState,
      dialogWaringOpened: false,
    }));
  }

  async getAccounts() {
    ApiAdmin.getUsersAccounts().then((response) => {
      var dateOptions = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "numeric",
      };

      let accountsDatas = [];

      if (response.status != 200 || !response.data || !response.data.accounts) {
        return;
      }

      response.data.accounts.forEach((account, i) => {
        accountsDatas.push({
          company: account.companyName,
          email: account.email,
          createdAt: new Date(account.createdAt).toLocaleDateString(
            "fr",
            dateOptions
          ),
          createdAtTime: new Date(account.createdAt).getTime(),
          firstname: account.firstname,
          lastname: account.lastname,
          id: account.id,
          role: account.role,
        });
      });

      this.setState((prevState) => ({
        ...prevState,
        accountsLoaded: true,
        accounts: accountsDatas,
      }));
    });
  }

  onDragEnd = ({ destination, source }) => {
    // // dropped outside the list
    // if (!destination) return;
    // const toursDatas = this.state.tours;
    // const [tourRemoved] = toursDatas.splice(source.index, 1);
    // toursDatas.splice(destination.index, 0, tourRemoved);
    // toursDatas.forEach((tour, id) => {
    //   tour.orderId = id;
    // });
    // this.setState(prevState => ({
    //   ...prevState,
    //   toursOrderIdChanged: true,
    //   toursLoaded: true,
    //   toursDatas: toursDatas
    // }), async () => {
    //   let res = await ApiTour.updateToursOrdersIds(this.state.tours);
    // });
  };

  async setStateIsAdmin() {
    let response = await ApiUser.isAdmin();

    if (response.status == 200) {
      this.setState((prevState) => ({
        ...prevState,
        isAdmin: response.data.isAdmin,
      }));
    }
  }

  render() {
    const { classes } = this.props;

    let accountsItems = [];
    if (this.state.accounts) {
      this.state.accounts.forEach((account) => {
        let item = {
          idInt: account.id,
          id: `account-${account.id}`,
          primary: account.company,
          secondary: account.email,
          primaryTwo: account.firstname,
          secondaryTwo: account.lastname,
          to: `/admin/manage-account?id=${account.id}`,
          icon: <AccountIcon />,
          isValid: true,
          createdAtTime: account.createdAtTime,
          role: account.role,
        };

        accountsItems.push(item);
      });
      accountsItems.sort((a, b) => {
        return b.createdAtTime - a.createdAtTime;
      });
    }

    if (!this.state.isAdmin && !this.state.isAdminSet) {
      return <div />;
    } else if (!this.state.isAdmin && this.state.isAdminSet) {
      return (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      );
    }

    return (
      <>
        <MainAppBar
          headerText="Comptes clients"
          actionText="Ajouter un utilisateur"
          actionUrl="/admin/signup-user"
          showHomeButton={true}
          showAdminButton={this.state.isAdmin}
        />
        {this.state.showError ? (
          <Alert severity="error">{this.state.errorMessage}</Alert>
        ) : (
          <div />
        )}
        {this.state.showSuccess ? (
          <Alert severity="success">{this.state.successMessage}</Alert>
        ) : (
          <div />
        )}
        <br />

        {/* <Grid container spacing={3}>
          <Grid item xs={4}> */}
        <Box className={`${classes.pannel}`} sx={{ p: 2, mt: 2, ml: 2 }}>
          <div style={{ color: "white", marginLeft: "50px" }}>
            {this.state.totalTilesRequestsCurrentMonth !== null ? (
              <>
                <h3>Requêtes de tuiles AWS</h3>
              </>
            ) : (
              <></>
            )}

            {this.state.totalTilesRequestsCurrentMonth !== null ? (
              <>
                <strong>Mois actuel &nbsp;:</strong>{" "}
                {this.state.totalTilesRequestsCurrentMonth} /{" "}
                {(this.state.totalTilesRequestsCurrentMonth / 1000) * 0.04} $
              </>
            ) : (
              <></>
            )}
            <br></br>
            {this.state.totalTilesRequestsPreviousMonth !== null ? (
              <>
                <strong>Il y a 1 mois :</strong>{" "}
                {this.state.totalTilesRequestsPreviousMonth} /{" "}
                {(this.state.totalTilesRequestsPreviousMonth / 1000) * 0.04} $
              </>
            ) : (
              <></>
            )}
            <br></br>
            {this.state.totalTilesRequestsThwoMonthAgo !== null ? (
              <>
                <strong>Il y a 2 mois :</strong>{" "}
                {this.state.totalTilesRequestsThwoMonthAgo} /{" "}
                {(this.state.totalTilesRequestsThwoMonthAgo / 1000) * 0.04} $
              </>
            ) : (
              <></>
            )}
          </div>
          <div className={classes.list}>
            <DialogWarning
              open={this.state.dialogWaringOpened}
              primaryText="Voulez vous vraiment archiver cette utilisateur ?"
              secondaryText="Cette utilisateur ne pourra plus se connecter à son compte."
              primaryAction={this.deleteAccount}
              closeAction={this.closeDeleteAccountDialog}
              idItem={this.state.userIdToDelete}
              primaryButtonText="Supprimer"
              secondaryButtonText="Annuler"
            />

            {this.state.accountsLoaded ? (
              <DraggableList
                items={accountsItems}
                onDragEnd={this.onDragEnd}
                onDelete={this.openDeleteAccountDialog}
              />
            ) : (
              <div></div>
            )}
          </div>
        </Box>
        {/* </Grid>
        </Grid> */}
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ManageAccounts);
