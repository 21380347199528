import React from "react";
import { Link } from "react-router-dom";
import {
  ListItemText,
  ListItem,
  ListItemSecondaryAction,
  ListItemAvatar,
  Avatar,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles({
  boldListItem: {
    fontWeight: 900,
  },
});

export default function ListItemLink(props) {
  const { icon, primary, secondary, to, deleteButton, disabled } = props;
  const classes = useStyles();

  const CustomLink = React.useMemo(
    () =>
      React.forwardRef((linkProps, ref) => (
        <Link ref={ref} to={to} {...linkProps} />
      )),
    [to]
  );

  return (
    <ListItem
      button
      component={CustomLink}
      disabled={disabled}
      style={{
        color: "white",
        backgroundColor: "#F48A21",
        borderBottomRightRadius: 6,
        borderBottomLeftRadius: 6,
        fontWeight: 900,
      }}
      className={classes.boldListItem}
    >
      <ListItemText
        primary={primary}
        secondary={secondary}
        style={{
          color: "white",
          fontWeight: 900,
          textAlign: "center",
        }}
        className={classes.boldListItem}
      />
      <ListItemSecondaryAction>
        {deleteButton ? (
          <IconButton edge="end" aria-label="delete">
            <DeleteIcon />
          </IconButton>
        ) : (
          <div />
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
}
