import Axios from "axios";

// import JwtUtils from "../utils/jwtUtils";
import { apiHost } from "../constants/ApiConstants";
import apiClient from "./ApiClient";
export default class ApiZone {
  static async uploadZone(zoneDatas, tourId) {
    // const tokens = await JwtUtils.getValidTokens();
    // if (!tokens) {
    //   return { status: null, data: null };
    // }
    // const authHeader = "Bearer " + tokens.accessToken;

    return await apiClient
      .post(
        `${apiHost}/newZone`,
        {
          zoneDatas: zoneDatas,
          tourId: tourId,
        },
        {
          // headers: { Authorization: authHeader },
        }
      )
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response.status,
            data: error.response.data,
          };
        }

        return errorLog;
      });
  }

  static async updateZone(zoneDatas, tourId, zoneId) {
    // const tokens = await JwtUtils.getValidTokens();
    // if (!tokens) {
    //   return { status: null, data: null };
    // }
    // const authHeader = "Bearer " + tokens.accessToken;

    return await apiClient
      .post(
        `${apiHost}/updateZone`,
        {
          zoneDatas: zoneDatas,
          tourId: tourId,
          zoneId: zoneId,
        },
        {
          // headers: { Authorization: authHeader },
        }
      )
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response.status,
            data: error.response.data,
          };
        }

        return errorLog;
      });
  }

  static async updateZonesOrdersIds(zonesDatas) {
    // const tokens = await JwtUtils.getValidTokens();
    // if (!tokens) {
    //   return { status: null, data: null };
    // }
    // const authHeader = "Bearer " + tokens.accessToken;

    return await apiClient
      .post(
        `${apiHost}/updateZonesOrderIds`,
        {
          zonesDatas: zonesDatas,
        },
        {
          // headers: { Authorization: authHeader },
        }
      )
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response.status,
            data: error.response.data,
          };
        }

        return errorLog;
      });
  }

  static async getZones(tourId) {
    // const tokens = await JwtUtils.getValidTokens();
    // if (!tokens) {
    //   return { status: null, data: null };
    // }
    // const authHeader = "Bearer " + tokens.accessToken;

    return await apiClient
      .get(`${apiHost}/zones`, {
        params: {
          tourId: tourId,
        },
        // headers: { Authorization: authHeader },
      })
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response.status,
            data: error.response.data,
          };
        }

        return errorLog;
      });
  }

  static async getZoneLinked(uuid, tourId) {
    // const tokens = await JwtUtils.getValidTokens();
    // if (!tokens) {
    //   return { status: null, data: null };
    // }
    // const authHeader = "Bearer " + tokens.accessToken;

    return await apiClient
      .get(`${apiHost}/zoneLinked`, {
        params: {
          uuid: uuid,
          tourId: tourId,
        },
        // headers: { Authorization: authHeader },
      })
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response.status,
            data: error.response.data,
          };
        }

        return errorLog;
      });
  }

  static async resetZoneLinkedUuid(tourId, zoneId) {
    // const tokens = await JwtUtils.getValidTokens();
    // if (!tokens) {
    //   return { status: null, data: null };
    // }
    // const authHeader = "Bearer " + tokens.accessToken;

    return await apiClient
      .post(
        `${apiHost}/resetQrCodeUuid`,
        {
          tourId: tourId,
          zoneId: zoneId,
        },
        {
          // headers: { Authorization: authHeader },
        }
      )
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response.status,
            data: error.response.data,
          };
        }

        return errorLog;
      });
  }

  static async getZone(tourId, zoneId) {
    // const tokens = await JwtUtils.getValidTokens();
    // if (!tokens) {
    //   return { status: null, data: null };
    // }
    // const authHeader = "Bearer " + tokens.accessToken;

    return await apiClient
      .get(`${apiHost}/zone`, {
        params: {
          tourId: tourId,
          zoneId: zoneId,
        },
        // headers: { Authorization: authHeader },
      })
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response.status,
            data: error.response.data,
          };
        }

        return errorLog;
      });
  }

  static async deleteZone(id) {
    // const tokens = await JwtUtils.getValidTokens();
    // if (!tokens) {
    //   return { status: null, data: null };
    // }
    // const authHeader = "Bearer " + tokens.accessToken;

    return await apiClient
      .delete(`${apiHost}/zone`, {
        params: {
          zoneId: id,
        },
        // headers: { Authorization: authHeader },
      })
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response.status,
            data: error.response.data,
          };
        }

        return errorLog;
      });
  }
}
