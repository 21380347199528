import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import qs from "qs";

import ApiUser from "../../../API/ApiUser";
import styles from "./resetPasswordStyles";
import ResetPasswordView from "./resetPasswordView";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: false,
      errorMessage: "",
      showSuccess: false,
      successMessage: "",
      newPassword: "",
      newPasswordVerify: "",
      token: "",
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  componentDidMount() {
    document.title = "Changer de mot de passe | Landing Zone";

    let token = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).token;
    if (!token) {
      this.setState((prevState) => ({
        ...prevState,
        showError: true,
        errorMessage: "Le lien est invalide",
        showSuccess: false,
      }));

      return;
    }

    this.setState((prevState) => ({
      ...prevState,
      token: token,
    }));
  }

  handleFormChange(event) {
    this.setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }

  async changePassword(event) {
    event.preventDefault();

    let newPassword = this.state.newPassword;
    let newPasswordVerify = this.state.newPasswordVerify;

    if (newPassword != newPasswordVerify) {
      this.setState((prevState) => ({
        ...prevState,
        showError: true,
        showSuccess: false,
        errorMessage: "Les mots de passes ne corréspondent pas",
      }));

      return;
    }

    if (newPassword.length < 8) {
      this.setState((prevState) => ({
        ...prevState,
        showError: true,
        showSuccess: false,
        errorMessage:
          "Votre mot de passe doit contenir au minimum 8 carractères",
      }));

      return;
    }

    if (!this.state.token) {
      return;
    }

    let res = await ApiUser.resetPassword(this.state.token, newPassword);

    if (res.status == 408) {
      this.setState((prevState) => ({
        ...prevState,
        showError: true,
        showSuccess: false,
        errorMessage: "Ce lien n'est plus valide",
      }));
    } else if (res.status != 200) {
      this.setState((prevState) => ({
        ...prevState,
        showError: true,
        showSuccess: false,
        errorMessage: "Ce lien n'est pas valide",
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        showError: false,
        showSuccess: true,
        successMessage: "Votre mot de passe à été réinitialisé",
      }));
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <ResetPasswordView
        {...this.props}
        state={this.state}
        handleFormChange={this.handleFormChange}
        changePassword={this.changePassword}
        classes={classes}
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(ResetPassword)
);
