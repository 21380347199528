const styles = (theme) => ({
  formContainer: {
    paddingTop: "0px",
    paddingBottom: "30px",
    width: "60ch",
    margin: "auto",
    textAlign: "center",
  },
  card: {
    marginLeft: 32,
    height: 40,
    width: 40,
  },
  appBar: {
    backgroundColor: theme.palette.darkGrey.main,
    paddingBottom: 24,
    paddingTop: 24,
  },
  container: {
    marginTop: 150,
    padding: 30,
    borderRadius: 22,
  },
  backgroundImg: {
    backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url('/background-login.png')`,
    backgroundSize: "cover",
    backgroundPosition: "center top",
    height: "100vh",
    backgroundRepeat: "repeat-y",
  },
  title: {
    fontSize: 20,
    fontWeight: 800,
    marginBottom: 20,
    marginTop: 20,
    color: "white",
    textAlign: "center",
  },
  subtitle: {
    textAlign: "center",
    fontSize: 16,
    fontWeight: 500,
    color: "white",
  },
});

export default styles;
