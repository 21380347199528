import React, { Component } from "react";
import Alert from "@material-ui/lab/Alert";
import { AppBar, Box, Button, Card, CardMedia } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";

const RequestResetPasswordView = (props) => {
  return (
    <>
      <Box
        className={props.classes.backgroundImg}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <AppBar className={props.classes.appBar}>
          <Card className={props.classes.card}>
            <CardMedia
              component="img"
              alt="Description de l'image"
              height="40"
              width="40"
              image="/logo192.png"
              title="Titre de l'image"
            />
          </Card>
        </AppBar>
        <div>
          <Box
            className={props.classes.container}
            sx={{
              width: 500,
              height: 450,
              bgcolor: "primary.main",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              color: "white",
            }}
          >
            <form
              onSubmit={props.requestResetPassword}
              className={props.classes.formContainer}
            >
              <br />
              <h1>Réinitialisation du mot de passe</h1>
              <p>
                Pour réinitialiser votre mot de passe, veuillez saisir votre
                adresse e-mail dans le champ ci-dessous. Nous vous enverrons un
                e-mail contenant un lien pour créer un nouveau mot de passe.
              </p>
              <div>
                <div>
                  <TextField
                    required
                    variant="outlined"
                    size="small"
                    name="email"
                    onChange={props.handleFormChange}
                    label="Email"
                    className={props.classes.textfield}
                  />
                  <br />
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    className={props.classes.validateForm}
                  >
                    Valider
                  </Button>
                </div>
              </div>
              <Link className={props.classes.connect} to="/login">
                Connexion
              </Link>
            </form>
          </Box>
        </div>
        <Box
          sx={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            marginTop: 12,
          }}
        >
          {props.state.showError ? (
            <Alert severity="error">{props.state.errorMessage}</Alert>
          ) : (
            <div />
          )}
          {props.state.showSuccess ? (
            <Alert severity="success">{props.state.successMessage}</Alert>
          ) : (
            <div />
          )}
        </Box>
      </Box>
    </>
  );
};

export default RequestResetPasswordView;
