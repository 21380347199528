import React from "react";
import Alert from "@material-ui/lab/Alert";

import DraggableList from "../../components/draggableList";
import DialogWarning from "../../components/dialogWarning";
import MainAppBar from "../../components/MainAppBar";
import { Box, Grid, Typography } from "@material-ui/core";
import AnalycticsListComponent from "./components/analyticsListComponent";

const AnalyticsView = (props) => {
  return (
    <>
      <Box className={`${props.classes.pannel}`} sx={{ p: 2, mt: 2, mr: 2 }}>
        <AnalycticsListComponent
          {...props}
          tourId={props.state.tourIdSelected}
        ></AnalycticsListComponent>
      </Box>
    </>
  );
};

export default AnalyticsView;
