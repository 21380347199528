import React from "react";
import { Box } from "@material-ui/core";
import { Info } from "@material-ui/icons";

const UnsavedChangesIndicator = ({ show }) => {
  if (!show) return null;

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        right: 0,
        marginRight: 24,
        marginBottom: 18,
        padding: 6,
        backgroundColor: "#768FDD",
        color: "#ffffff",
        borderRadius: 12,
        zIndex: 1000,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Info style={{ marginRight: "6px" }}></Info>
      Modifications non sauvegardées
    </Box>
  );
};

export default UnsavedChangesIndicator;
