import Axios from "axios";

// import JwtUtils from "../utils/jwtUtils";
import { apiHost } from "../constants/ApiConstants";
import apiClient from "./ApiClient";

export default class ApiTour {
  static async uploadTour(tourDatas) {
    // const tokens = await JwtUtils.getValidTokens();
    // if (!tokens) {
    //   return { status: null, data: null };
    // }
    // const authHeader = "Bearer " + tokens.accessToken;

    const dataForm = new FormData();

    dataForm.append(
      "imageCollection",
      tourDatas.backgroundImageFile,
      "-backgroundimage-" + tourDatas.backgroundImageFile.name
    );
    if (tourDatas.annotationImageFile)
      dataForm.append(
        "imageCollection",
        tourDatas.annotationImageFile,
        "-annotationimage-" + tourDatas.annotationImageFile.name
      );
    if (tourDatas.inGameBackgroundImageFile)
      dataForm.append(
        "imageCollection",
        tourDatas.inGameBackgroundImageFile,
        "-ingamebackgroundimage-" + tourDatas.inGameBackgroundImageFile.name
      );
    dataForm.append("tourDatas", JSON.stringify(tourDatas));

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    return await apiClient
      .post(
        `${apiHost}/newTour`,
        dataForm,
        {
          // headers: { Authorization: authHeader },
        },
        config
      )
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response.status,
            data: error.response.data,
          };
        }

        return errorLog;
      });
  }

  static async newGuestUserTourCode(tourCode) {
    // const tokens = await JwtUtils.getValidTokens();
    // if (!tokens) {
    //   return { status: null, data: null };
    // }
    // const authHeader = "Bearer " + tokens.accessToken;

    return await apiClient
      .post(`${apiHost}/newGuestUserTourCode`, tourCode, {
        // headers: { Authorization: authHeader },
      })
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response.status,
            data: error.response.data,
          };
        }

        return errorLog;
      });
  }

  static async updateToursOrdersIds(toursDatas) {
    // const tokens = await JwtUtils.getValidTokens();
    // if (!tokens) {
    //   return { status: null, data: null };
    // }
    // const authHeader = "Bearer " + tokens.accessToken;

    return await apiClient
      .post(
        `${apiHost}/updateToursOrderIds`,
        {
          toursDatas: toursDatas,
        },
        {
          // headers: { Authorization: authHeader },
        }
      )
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response.status,
            data: error.response.data,
          };
        }

        return errorLog;
      });
  }

  static async updateTour(tourDatas, tourId) {
    // const tokens = await JwtUtils.getValidTokens();
    // if (!tokens) {
    //   return { status: null, data: null };
    // }
    // const authHeader = "Bearer " + tokens.accessToken;

    const dataForm = new FormData();

    if (tourDatas.backgroundImageFile)
      dataForm.append(
        "imageCollection",
        tourDatas.backgroundImageFile,
        "-backgroundimage-" + tourDatas.backgroundImageFile.name
      );
    if (tourDatas.annotationImageFile)
      dataForm.append(
        "imageCollection",
        tourDatas.annotationImageFile,
        "-annotationimage-" + tourDatas.annotationImageFile.name
      );
    if (tourDatas.inGameBackgroundImageFile)
      dataForm.append(
        "imageCollection",
        tourDatas.inGameBackgroundImageFile,
        "-ingamebackgroundimage-" + tourDatas.inGameBackgroundImageFile.name
      );

    dataForm.append("tourDatas", JSON.stringify(tourDatas));
    dataForm.append("tourId", tourId);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        // Authorization: authHeader,
      },
    };

    return await apiClient
      .post(`${apiHost}/updateTour`, dataForm, config)
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response.status,
            data: error.response.data,
          };
        }

        return errorLog;
      });
  }

  static async getTours() {
    // const tokens = await JwtUtils.getValidTokens();
    // if (!tokens) {
    //   return { status: null, data: null };
    // }
    // const authHeader = "Bearer " + tokens.accessToken;

    return await apiClient
      .get(`${apiHost}/tours`, {
        // headers: { Authorization: authHeader },
      })
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response.status,
            data: error.response.data,
          };
        }

        return errorLog;
      });
  }

  static async getTour(id) {
    // const tokens = await JwtUtils.getValidTokens();
    // if (!tokens) {
    //   return { status: null, data: null };
    // }
    // const authHeader = "Bearer " + tokens.accessToken;

    return await apiClient
      .get(`${apiHost}/tour`, {
        params: {
          tourId: id,
        },
        // headers: { Authorization: authHeader },
      })
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response.status,
            data: error.response.data,
          };
        }

        return errorLog;
      });
  }

  static async getTourPublic(id) {
    return await apiClient
      .get(`${apiHost}/tourPublic`, {
        params: {
          tourId: id,
        },
      })
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response.status,
            data: error.response.data,
          };
        }

        return errorLog;
      });
  }

  static async getTourCodes(tourId, searchTerm, page, pageSize) {
    // const tokens = await JwtUtils.getValidTokens();
    // if (!tokens) {
    //   return { status: null, data: null };
    // }
    // const authHeader = "Bearer " + tokens.accessToken;

    return await apiClient
      .get(`${apiHost}/tourCodes`, {
        params: {
          tourId: tourId,
          page: page,
          pageSize: pageSize,
          searchTerm: searchTerm,
        },
        // headers: { Authorization: authHeader },
      })
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response.status,
            data: error.response.data,
          };
        }

        return errorLog;
      });
  }

  static async duplicateTour(tourId) {
    // const tokens = await JwtUtils.getValidTokens();
    // if (!tokens) {
    //   return { status: null, data: null };
    // }
    // const authHeader = "Bearer " + tokens.accessToken;

    return await apiClient
      .post(
        `${apiHost}/duplicateTour`,
        {
          tourId: tourId,
        },
        {
          // headers: { Authorization: authHeader },
        }
      )
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response.status,
            data: error.response.data,
          };
        }

        return errorLog;
      });
  }

  static async sendEmailNewCode(tourCodeId) {
    // const tokens = await JwtUtils.getValidTokens();
    // if (!tokens) {
    //   return { status: null, data: null };
    // }
    // const authHeader = "Bearer " + tokens.accessToken;

    return await apiClient
      .post(
        `${apiHost}/sendEmailNewCode`,
        {
          tourCodeId: tourCodeId,
        },
        {
          // headers: { Authorization: authHeader },
        }
      )
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response.status,
            data: error.response.data,
          };
        }

        return errorLog;
      });
  }

  static async archiveTourCode(id) {
    // const tokens = await JwtUtils.getValidTokens();
    // if (!tokens) {
    //   return { status: null, data: null };
    // }
    // const authHeader = "Bearer " + tokens.accessToken;

    return await apiClient
      .post(
        `${apiHost}/archiveTourCode`,
        {},
        {
          params: {
            tourCodeId: id,
          },
          // headers: { Authorization: authHeader },
        }
      )
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response.status,
            data: error.response.data,
          };
        }

        return errorLog;
      });
  }
  static async deleteTour(id) {
    // const tokens = await JwtUtils.getValidTokens();
    // if (!tokens) {
    //   return { status: null, data: null };
    // }
    // const authHeader = "Bearer " + tokens.accessToken;

    return await apiClient
      .delete(`${apiHost}/tour`, {
        params: {
          tourId: id,
        },
        // headers: { Authorization: authHeader },
      })
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response.status,
            data: error.response.data,
          };
        }

        return errorLog;
      });
  }
}
