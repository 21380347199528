import React from "react";
import TextField from "@material-ui/core/TextField";
import {
  CardMedia,
  Card,
  Typography,
  Button,
  Slider,
  Divider,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { Edit, AccountCircle } from "@material-ui/icons";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import ImageUtils from "../../../utils/imageUtils";
import DraggableList from "../../components/draggableList";
import ListItemLink from "../../components/listItemLink";
import DialogWarning from "../../components/dialogWarning";
import LoadingView from "../../components/loadingView";
import MainAppBar from "../../components/MainAppBar";
import { GameMode } from "../../../constants/TourConstants";

const TourSelectedView = (props) => {
  return (
    <>
      <MainAppBar
        headerText="Parcours"
        showHomeButton={true}
        showAdminButton={props.state.isAdmin}
      />
      <Container className={props.classes.container} maxWidth="md">
        <Typography
          className={props.classes.title}
          id="discrete-slider"
          gutterBottom
        >
          {props.state.title}
        </Typography>
        <div className={props.classes.buttonContainer}>
          <Link to={`/parcours-codes?id=${props.state.tourId}`}>
            <Button
              className={props.classes.menuButtonUsers}
              variant="outlined"
              color="darkGrey"
              startIcon={<AccountCircle />}
            >
              UTILISATEURS
            </Button>
          </Link>
          <Link to={`/parcours?id=${props.state.tourId}`}>
            <Button
              className={props.classes.menuButtonEdit}
              variant="outlined"
              color="darkGrey"
              startIcon={<Edit />}
            >
              ÉDITER
            </Button>
          </Link>
        </div>
        {props.state.showError ? (
          <Alert severity="error">{props.state.errorMessage}</Alert>
        ) : (
          <div />
        )}
      </Container>
    </>
  );
};

export default TourSelectedView;
