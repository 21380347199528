import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MapIcon from "@material-ui/icons/Map";
import EditIcon from "@material-ui/icons/Map";

import ApiTour from "../../../../../API/ApiTour";
import styles from "./parcoursListStyles";
import ParcoursListView from "./parcoursListView";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import ApiAdmin from "../../../../../API/ApiAdmin";
import { withTranslation } from "react-i18next";

class ParcoursListComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showError: false,
      errorMessage: "",
      showSuccess: false,
      successMessage: "",
      toursOrderIdChanged: false,
      toursLoaded: false,
      dialogWaringOpened: false,
      tourIdToDelete: -1,
      tours: [
        {
          name: "",
          createdAt: "",
          isValid: false,
          orderId: 0,
          id: 0,
        },
      ],
    };

    this.onDragEnd = this.onDragEnd.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.deleteTour = this.deleteTour.bind(this);
    this.openDeleteTourDialog = this.openDeleteTourDialog.bind(this);
    this.closeDeleteTourDialog = this.closeDeleteTourDialog.bind(this);
    this.duplicateTour = this.duplicateTour.bind(this);
  }

  componentDidMount() {
    this.getTours();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.toursReload !== this.props.toursReload) {
      this.getTours();
    }
  }

  setToursDatas(tours) {
    var dateOptions = {
      weekday: "short",
      year: "numeric",
      month: "2-digit",
      day: "numeric",
    };

    let toursDatas = [];
    console.log("tours.data ", tours.data);
    if (!tours.data || typeof tours.data === "string") return;

    tours.data.forEach((elem, i) => {
      toursDatas.push({
        UserId: elem.UserId,
        name: elem.title,
        createdAt: new Date(elem.createdAt).toLocaleDateString(
          "fr",
          dateOptions
        ),
        id: elem.id,
        isValid: elem.isValid,
      });
    });

    if (tours.data.length) {
      this.props.onTourSelection(tours.data[0].id);
    }

    this.setState((prevState) => ({
      ...prevState,
      toursLoaded: true,
      tours: toursDatas,
    }));
  }

  async getTours() {
    if (this.props.isAdminView && this.props.clientId) {
      ApiAdmin.getToursClient(this.props.clientId).then((tours) => {
        this.setToursDatas(tours);
      });
    } else {
      ApiTour.getTours().then((tours) => {
        this.setToursDatas(tours);
      });
    }
  }

  async duplicateTour(tourId) {
    const { t } = this.props;
    let res = null;
    if (this.props.isAdminView && this.props.clientId) {
      res = await ApiAdmin.duplicateAdminTourOnUserAccount(
        tourId,
        this.props.clientId
      );
    } else {
      res = await ApiTour.duplicateTour(tourId);
    }

    if (res.status == 201) {
      this.getTours();

      this.setState((prevState) => ({
        ...prevState,
        showSuccess: true,
        showError: false,
        successMessage: t("parcours_list.successMessageDuplicateTour"),
        dialogWaringOpened: false,
      }));
    } else if (res.status == 403) {
      this.setState((prevState) => ({
        ...prevState,
        showSuccess: false,
        showError: true,
        errorMessage: t("parcours_list.errorMessageMaxToursReached"),
        dialogWaringOpened: false,
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        showSuccess: false,
        showError: true,
        errorMessage: t("parcours_list.genericErrorMessage"),
        dialogWaringOpened: false,
      }));
    }
  }

  onDragEnd = ({ destination, source }) => {
    // dropped outside the list
    if (!destination) return;

    const toursDatas = this.state.tours;

    const [tourRemoved] = toursDatas.splice(source.index, 1);
    toursDatas.splice(destination.index, 0, tourRemoved);

    toursDatas.forEach((tour, id) => {
      tour.orderId = id;
    });

    this.setState(
      (prevState) => ({
        ...prevState,
        toursOrderIdChanged: true,
        toursLoaded: true,
        toursDatas: toursDatas,
      }),
      async () => {
        let res = await ApiTour.updateToursOrdersIds(this.state.tours);
      }
    );
  };

  async deleteTour(tourId) {
    const { t } = this.props;
    let res = null;
    if (this.props.isAdminView && this.props.clientId) {
      res = await ApiAdmin.deleteTour(tourId, this.props.clientId);
    } else {
      res = await ApiTour.deleteTour(tourId);
    }

    if (res.status == 200) {
      let newTours = this.state.tours;
      newTours.forEach((tour, index) => {
        if (tour.id == tourId) {
          newTours.splice(index, 1);
        }
      });
      this.setState((prevState) => ({
        ...prevState,
        showSuccess: true,
        successMessage: t("parcours_list.successMessageDeleteTour"),
        dialogWaringOpened: false,
        tours: newTours,
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        showError: true,
        errorMessage: t("parcours_list.errorMessageDeleteTour"),
        showSuccess: false,
        dialogWaringOpened: false,
      }));
    }
  }

  openDeleteTourDialog(tourId) {
    this.setState((prevState) => ({
      ...prevState,
      tourIdToDelete: tourId,
      dialogWaringOpened: true,
    }));
  }

  closeDeleteTourDialog() {
    this.setState((prevState) => ({
      ...prevState,
      dialogWaringOpened: false,
    }));
  }

  onEdit(tourId) {
    this.props.history.push(`/parcours?id=${tourId}`);
  }

  render() {
    let toursItems = [];
    if (this.state.tours) {
      this.state.tours.forEach((tour) => {
        let item = {
          idInt: tour.id,
          id: `tour-${tour.id}`,
          primary: tour.name,
          secondary: tour.createdAt,
          to: `/parcours?id=${tour.id}`,
          icon: <MapIcon />,
          editIcon: <EditIcon />,
          isValid: tour.isValid,
        };

        toursItems.push(item);
      });
    }

    return (
      <>
        <ParcoursListView
          {...this.props}
          toursItems={toursItems}
          state={this.state}
          role={this.props.role}
          onEdit={this.onEdit}
          onDragEnd={this.onDragEnd}
          openDeleteTourDialog={this.openDeleteTourDialog}
          deleteTour={this.deleteTour}
          closeDeleteTourDialog={this.closeDeleteTourDialog}
          duplicateTour={this.duplicateTour}
        />
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation()(ParcoursListComponent)
);
